<template>
  <!-- 标签 -->
  <!-- <div class="new-msgItem-badge" ></div> -->
  <div class="img_container_p">
    <div class="img_container">
      <img class="uImage img" :src="msgItem.avatar || '@assets/img/uimg/img-avatar01.jpg'"
        @click="showUinfoLayer(msgItem, $event)" />
    </div>
    <em class="m_vui__badge" v-if="msgItem.new_msg_count > 0">{{
      msgItem.new_msg_count
    }}</em>
  </div>

  <div class="info_container overflow_ellipsis">
    <div style="display: flex; flex-direction: column">
      <div class="c1 flex_center" style="flex: 1">
        <div v-if="msgItem.use_robot === 1" class="icon_item">
          <el-popover placement="top-start" width="200" trigger="hover">
            <div style="min-height: 50px; font-size: 12px">
              <div class="m_desc m_border_b" style="margin-bottom: 10px">
                账号的AI客服接管开启后，AI会自动回复此账号下可会话客户的对话。
              </div>
              <div v-if="msgItem.use_robot_status === 1">
                AI客服状态： <span class="m_color_s2">开启</span>
              </div>
              <div v-else>AI客服状态： <span class="m_color_e">关闭</span></div>
              <div>
                AI客服：<span class="m_desc">{{ msgItem.robot_name }}</span>
              </div>
              <div>
                AI客服接管:
                <el-switch v-model="msgItem.use_robot_status" size="small" active-text="" inactive-text=""
                  :active-value="1" :inactive-value="0" @change="handleUseRobotStatusChange(msgItem)" />
              </div>
            </div>
            <template #reference>
              <i class="iconfont icon-zhinengkefuzhongxin" :class="{ m_color_s22: msgItem.use_robot_status === 1 }"></i>
            </template>
          </el-popover>
        </div>

        <i class="iconfont icon-zhinengwangyehuise" style="color: #000"></i>
        <span class="m_color_2" style="margin-left: 3px">{{
          msgItem.username
        }}</span>
        <div class="m_border_r m_desc overflow_ellipsis" style="margin-left: 3px; font-size: 11px; padding: 0 3px">
          {{
            $syscfgNameByValue("customer_type_list", msgItem.customer_type_id)
          }}
        </div>
      </div>
    </div>
    <!-- <div class="r2">
              
              </div> -->
  </div>
</template>


<script>
import { computed, onMounted, ref, watch } from "vue";
export default {
  props: { item: { type: Object } },
  emits: ["showAccountInfo", "changeAiStatus"],
  setup(props, { emit }) {
    // -------------- 属性 ----------------
    // setup() 接收 props 作为第一个参数
    const msgItem = computed(() => {
      return props.item;
    });

    // --------------- 方法 --------------
    const handleUseRobotStatusChange = (rowItem) => {
      emit("changeAiStatus", rowItem);
    };

    const showUinfoLayer = (rowItem, e) => {
      console.log("--------------------");
      let pos = [e.clientX, e.clientY];
      emit("showAccountInfo", { rowItem, pos });
      e.stopPropagation();
    };
    return {
      msgItem,
      showUinfoLayer,
      handleUseRobotStatusChange,
    };
  },
};
</script>

<style lang="scss" scoped>
.img_container_p {
  position: relative;

  .m_vui__badge {
    position: absolute;
    top: -5px;
    right: -5px;
    z-index: 998;
  }

  .img_container {
    width: 30px;
    height: 30px;
    overflow: hidden;
    border-radius: 100%;
    position: relative;
    margin-left: 10px;

    .img {
      object-fit: fill;
      width: 30px;
      height: 30px;
    }

    .image_cover {
      z-index: 11;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, .8);
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: white;
    }
  }
}

.info_container {
  box-sizing: border-box;
  margin: 5px 5px;
  display: flex;
  align-items: center;

  .c1 {
    .icon_item {
      margin-right: 3px;
    }
  }

}
</style>