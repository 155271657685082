<template>
  <div class="msgitem" :class="imgeMessageItem.isme ? 'me' : 'others'">
    <router-link v-if="!imgeMessageItem.isme" class="avatar" to="/contact/uinfo"
      ><img :src="imgeMessageItem.avatar"
    /></router-link>
    <div class="content">
      <p class="author">{{ imgeMessageItem.author }}</p>
      <div :class="{ me_msg_ontainer: imgeMessageItem.isme }">
        <div
            class="lds-ring"
            v-if="imgeMessageItem.isme && imgeMessageItem.send_status == 2"
          >
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        <div v-if="imgeMessageItem.isme && imgeMessageItem.send_status === 0">
          <el-popover
            placement="top-start"
            :width="200"
            trigger="hover"
            :content=" imgeMessageItem.error_msg"
          >
            <template #reference>
              <i class="iconfont icon-Shapex" style="color:red"></i>
            </template>
          </el-popover>
        </div>

        <div>
          <el-popover
              trigger="contextmenu"
            >
              <template #reference>
                <div class="msg picture">
                  <el-image
                    class="img__pic"
                    :src="imgeMessageItem.imgsrc"
                    :preview-src-list="[imgeMessageItem.imgsrc]"
                    hide-on-click-modal
                  />
                </div>
              </template>
              <template #default>
                <div>
                  <div class="contextmenu_container" v-if="imgeMessageItem.isme">
                    <p class="contextmenu_item" @click="handlRevocationMsg(imgeMessageItem)">撤回</p>
                  </div>
                </div>
              
              </template>
            </el-popover>

            <div style="clear: both; text-align: center;color:#aaa;font-size: 10px;padding-top: 5px;">
               <p v-if="imgeMessageItem.is_delete == 1">已删除</p>
               <p v-if="imgeMessageItem.is_delete == 2">已撤回</p>
            </div>
        </div>

      


      </div>
    </div>
    <router-link v-if="imgeMessageItem.isme" class="avatar" to="/contact/uinfo"
      ><img :src="imgeMessageItem.avatar"
    /></router-link>
  </div>
</template>
    <script>
// 引入动态组件（中间栏）
import { toRefs, onMounted, reactive,computed } from "vue";
// import { useStore } from "vuex";
import {
  revocationMsg
} from "@/api/chatapi";
export default {
  components: {},
  props: {
    msgItem: { 
      type: [Object],
      default: () => {
        return {};
      },
    },
  },
  setup(props) {
    const data = reactive({
     
    });

    onMounted(() => {});
    const imgeMessageItem = computed(()=>{
      return props.msgItem
    })
    //撤销消息
    const handlRevocationMsg = (msgitem)=>{
      revocationMsg({id:msgitem.id})
    }
    return {
      ...toRefs(data),
      imgeMessageItem,
      handlRevocationMsg
    };
  },
};
</script>
  
  <style lang="scss" scoped>
 .me_msg_ontainer {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
}
.lds-ring {
  /* change color here */
  color: #1c4c5b;
  padding: 5px;
}
.lds-ring,
.lds-ring div {
  box-sizing: border-box;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  margin: 2px;
  border: 2px solid currentColor;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: currentColor transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
       
       