<!-- //工具栏模块 -->
<template>
  <div class="nt__toolbar">
    <!-- <div
      v-if="!route.meta.hideMiddle"
      :class="['collapse', store.state.user.isSideCollapsed && 'on']"
      @click="handleSideCollapsed"
    >
      <i class="iconfont icon-shousuo"></i>
    </div> -->
    <div class="avatar">
      <img
        class="uImage"
        :src="userInfo?.avatar || '@assets/img/uimg/img-avatar01.jpg'"
        @click="showUinfoLayer"
      />
      <el-popover
        ref="popoverRef"
        placement="right"
        title="客服状态"
        trigger="hover"
        :width="400"
      >
        <template #reference>
          <span
            class="uStatus"
            :class="userServiceStatus"
            ref="userServiceStatusRef"
          ></span>
        </template>
        <div>
          <div class="rlay__uStatus">
            <span class="status online" @click="handleStatus(1)">
              <span
                class="m_color_s flex_center"
                style="
                  font-weight: bold;
                  display: inline-block;
                  padding-left: 5px;
                "
                >在线</span
              >
              可接待客户
            </span>
            <span class="status offline" @click="handleStatus(2)">
              <span
                class="offline flex_center"
                style="
                  font-weight: bold;
                  display: inline-block;
                  padding-left: 5px;
                "
                >小休</span
              >
              可接待当前客户，无法接待新客户
              <el-tooltip
                content="请注意：如果没有其他可接单的在线私信客服还是会推送新客户"
                placement="bottom"
              >
                <i class="iconfont icon-tishi1"></i>
              </el-tooltip>
            </span>
          </div>
        </div>
      </el-popover>
    </div>
    <div class="navbar flex1">
      <div class="ls">
        <!-- 聊天界面 -->
        <router-link
          to="/chat/group-chat"
          class="link"
          exact
          @click="handleClickUtil(1)"
          :class="{ on: selectItem == 1 }"
          ><span class="ico"
            ><i class="iconfont icon-xiaoxi2"></i>
            <!-- <em class="vui__badge">21</em> -->
          </span>
        </router-link>
      </div>
      <!-- 统计信息 -->
      <!-- <div class="ls">
        <router-link to="/statistics/index" class="link"  exact @click="handleClickUtil(2)" :class="{on:selectItem == 2}"
          ><span class="ico"
            ><i class="iconfont icon-tongjixinxi"></i>
            </span
          >
        </router-link>
      </div> -->

      <!-- <div class="ls">
        <router-link to="/contact" class="link"   @click="handleClickUtil(2)" :class="{on:selectItem == 2}"
          ><span class="ico"
            ><i class="iconfont icon-haoyou"></i
            ><em class="vui__badge">1</em></span
          >
        </router-link>
      </div>
      <div class="ls">
        <router-link to="/my/favorite" class="link"  @click="handleClickUtil(3)" :class="{on:selectItem == 3}"
          ><span class="ico"><i class="iconfont icon-shoucang"></i></span>
        </router-link>
      </div>
      <div class="ls">
        <router-link to="/fzone" class="link"   @click="handleClickUtil(4)" :class="{on:selectItem == 4}"
          ><span class="ico"
            ><i class="iconfont icon-pyq2"></i><em class="vui__badge-dot"></em
          ></span>
        </router-link>
      </div> -->
    </div>
    <div class="navbar navbar2 mb-10">
      <div class="ls" @click="handleRefresh">
        <a class="link">
          <el-tooltip
            class="box-item"
            effect="light"
            content="刷新系统缓存"
            placement="right"
          >
            <span class="ico"><i class="iconfont icon-shuaxin"></i></span>
          </el-tooltip>
        </a>
      </div>
      <div class="ls" @click="isShowAboutLayer = true">
        <a class="link"
          ><span class="ico"><i class="iconfont icon-about"></i></span
        ></a>
      </div>
      <div class="ls" id="skin" @click="isShowSkinLayer = true">
        <a class="link"
          ><span class="ico"><i class="iconfont icon-huanfu"></i></span
        ></a>
      </div>
      <div class="ls" @click="handleShowSetting">
        <a class="link"
          ><span class="ico"><i class="iconfont icon-peizhi2"></i></span
        ></a>
      </div>
      <div class="ls" id="logout" @click="handleLogout">
        <a class="link"
          ><span class="ico"
            ><i
              :class="{
                ws_connected: websocketStatus,
                ws_un_connected: !websocketStatus,
              }"
              class="iconfont icon-tuichu"
            ></i></span
        ></a>
      </div>
    </div>

    <!-- <v3-layer
      v-model="isShowStatusLayer"
      follow=".uStatus"
      opacity="0"
      teleport="body"
    >
            <div>
              <div class="rlay__uStatus">
                    <span class="deliver vui__hairline-bottom"></span>
                    <span class="status online" @click="handleStatus(0)">在线</span
                    ><span class="status offline" @click="handleStatus(1)">离开</span>
                    <span class="status busy" @click="handleStatus(2)">忙碌</span
                    ><span class="status invisible" @click="handleStatus(3)">隐身</span>
            
                  </div>
            </div>

    </v3-layer> -->
    <!-- 卡片模板 -->
    <v3-layer
      v-model="isShowUinfoLayer"
      :follow="uInfoLayerPos"
      opacity="0"
      teleport="body"
    >
      <div class="rlay__uCards" style="width: 400px">
        <div class="item flexbox flex-alignc vui__hairline-bottom">
          <span class="lbl flex1"
            ><em class="fs-18">{{ userInfo?.nickname || "--" }}</em
            ><em class="iconfont icon-male ml-10 c-46b6ef"></em
            ><i>{{ userInfo?.username || "--" }}</i></span
          >
          <img
            class="uimg"
            :src="userInfo?.avatar || '@assets/img/uimg/img-avatar01.jpg'"
          />
        </div>
        <div class="item flexbox flex-alignc vui__hairline-bottom">
          <label class="lbl" style="margin-right: 10px">坐席信息</label>
          <div class="cnt flex1 c-999">
            坐席标识：[{{ userInfo?.seat_info?.id }}]
            <br />
            有效期至：{{ userInfo?.seat_info?.expire_time }}
          </div>
        </div>
        <div class="item flexbox flex-alignc vui__hairline-bottom">
          <label class="lbl" style="margin-right: 10px">地 区</label>
          <div class="cnt flex1 c-999">广东 广州</div>
        </div>
        <!-- <div class="item align-r">
          <a
            class="iconfont icon-tabbar1 fs-28 c-07c160"
            href="#"
            title="发消息"
          ></a>
        </div> -->
      </div>
    </v3-layer>
    <!-- 换肤模板 -->
    <v3-layer
      v-model="isShowSkinLayer"
      type="popover"
      anim="fadeIn"
      follow="#skin"
      opacity="0"
      teleport="body"
    >
      <div class="pad10">
        <h2 style="font-size: 18px; font-family: simsun; padding: 0 10px 10px">
          <i class="iconfont icon-huanfu"></i>换肤<em class="c-aaa fs-12"
            >「换一种背景，不一样的心情」</em
          >
        </h2>
        <div className="rlay__setSkin">
          <div class="item">
            <div class="bg" @click="handleSkin(null)">无</div>
          </div>
          <div class="item">
            <img
              class="bg"
              src="@assets/img/skin/img-skin01.jpg"
              @click="handleSkin(1)"
            />
          </div>
          <div class="item">
            <img
              class="bg"
              src="@assets/img/skin/img-skin02.jpg"
              @click="handleSkin(2)"
            />
          </div>
          <div class="item">
            <img
              class="bg"
              src="@assets/img/skin/img-skin03.jpg"
              @click="handleSkin(3)"
            />
          </div>
          <div class="item">
            <img
              class="bg"
              src="@assets/img/skin/img-skin04.jpg"
              @click="handleSkin(4)"
            />
          </div>
          <div class="item">
            <img
              class="bg"
              src="@assets/img/skin/img-skin05.jpg"
              @click="handleSkin(5)"
            />
          </div>
        </div>
      </div>
    </v3-layer>
    <!-- 关于模板 -->
    <v3-layer
      v-model="isShowAboutLayer"
      xclose
      :btns="[
        {
          text: 'close',
          style: 'color:#f37c15;',
          click: () => (isShowAboutLayer = false),
        },
      ]"
    >
      <div style="text-align: center; padding: 35px 25px; width: 300px">
        <img src="@assets/v3-logo.png" height="125" />
        <h2 class="c-f57b15 fs-24">私域宝.聚合聊天</h2>
        <div class="c-999 ff-gg mt-15">
          全平台私域<em class="fs-it">智能</em>客服系统
        </div>
        <p class="c-aaa fs-12 ff-gg mt-20 fs-it">Create by skyray</p>
        <p class="c-ddd fs-14 ff-gg fs-it">Tel:--</p>
      </div>
    </v3-layer>

    <SettingPage @logout="onLogout" ref="SettingPageRef" />
  </div>
</template>
<script>
import {
  onMounted,
  reactive,
  toRefs,
  inject,
  computed,
  ref,
  onUnmounted,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import SettingPage from "@/views/my/setting";
import { ElMessage } from "element-plus";
import { changeServiceStatus, logout } from "@/api/userapi";
export default {
  components: { SettingPage },
  setup() {
    const bus = inject("$eventBus");
    const wsCmd = inject("wsCmd");
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const SettingPageRef = ref(undefined);
    const v3layer = inject("v3layer");
    const data = reactive({
      status: "online",
      isShowUinfoLayer: false,
      uInfoLayerPos: null,
      isShowStatusLayer: false,
      isShowSkinLayer: false,
      isShowAboutLayer: false,
      isShowSettingLayer: false,
      selectItem: 1,
    });
    onMounted(() => {
      let skin = localStorage.getItem("skin") || store.state.user.skin;
      store.commit("user/SET_SKIN", skin);
      let _isSideCollapsed =
        localStorage.getItem("isSideCollapsed") ||
        store.state.user.isSideCollapsed;
      store.commit("user/SET_SIDECOLLAPSED", JSON.parse(_isSideCollapsed));
    });

    const userInfo = computed(() => {
      return store.state.user.user;
    });
    const websocketStatus = computed(() => {
      return store.state.websocket.socket.isConnected;
    });

    //提醒声音是否开启
    const noticeStatus = computed(() => {
      return store.state.profile.noticeStatus;
    });

    const userServiceStatus = computed(() => {
      switch (userInfo.value.service_status) {
        case 1:
          return "online";
        case 2:
          return "offline";
      }
      return userInfo.value.service_status;
    });

    const handleRefresh = ()=>{
      window.location.reload(true)
    }

    //设置在线状态
    const handleStatus = (index) => {
      switch (index) {
        case 1:
          data.status = "online";
          break;
        case 2:
          data.status = "offline";
          break;
        case 3:
          data.status = "busy";
          break;
        case 4:
          data.status = "invisible";
          break;
        default:
          data.status = null;
      }

      let param = {
        status: index,
      };
      changeServiceStatus(param).then(() => {
        // ElMessage({
        //   message: '请求已发送.',
        //   type: 'success',
        // })
      });
      // data.isShowStatusLayer = false;
      // v3layer.message({ content: "登录状态已更新！", xclose: true });
    };
    // 用户卡片
    const showUinfoLayer = (e) => {
      let pos = [e.clientX, e.clientY];
      data.isShowUinfoLayer = true;
      data.uInfoLayerPos = pos;
    };
    const handleSkin = (index) => {
      let skinUrl = index
        ? require(`@assets/img/skin/img-skin0${index}.jpg`)
        : "#";
      store.commit("user/SET_SKIN", skinUrl);
      data.isShowSkinLayer = false;
    };
    // 退出登录
    const handleLogout = () => {
      let $el = v3layer.popover({
        icon: "warning",
        title: "退出提示",
        content:
          '<div style="margin:-5px 10px 5px 0;">确定要退出当前登录账号吗？</div>',
        follow: "#logout",
        shade: true,
        opacity: 0,
        xclose: false,
        btns: [
          {
            text: "取消",
            click: () => {
              $el.close();
            },
          },
          {
            text: "确定",
            style: { background: "#f57b15" },
            click: () => {
              $el.close();
              onLogout();
            },
          },
        ],
      });
    };
    const onLogout = () => {
      ElMessage({
        showClose: false,
        message: "正在退出中，请稍候...",
        duration: 3000,
        onClose: () => {
          //退出登录接口
          logout();
          store.commit("user/LOGOUT");
          store.commit("wsData/CLEAR_SYS_CFG_INFO", {});
          store.commit("wsData/SET_CHATING_USERID", 0);
          router.push("/login");
        },
        type: "success",
      });

      // v3layer({
      //   content: "正在退出中，请稍候...",
      //   layerStyle: "background:rgba(0,0,0,.75);color:#fff;",
      //   shadeClose: false,
      //   time: 2,
      //   onEnd: () => {
      //     store.commit("user/LOGOUT");
      //     router.push("/login");
      //   },
      // });
    };
    // 收缩侧边栏
    const handleSideCollapsed = () => {
      store.commit(
        "user/SET_SIDECOLLAPSED",
        store.state.user.isSideCollapsed ? false : true
      );
    };

    const handleClickUtil = (item) => {
      data.selectItem = item;
      // if(item === 1){
      //   router.push("/chat/group-chat");
      // }else if(item === 2){
      //   router.push("/statistics/index");
      // }
    };

    const handleUserServiceStatusResp = (message) => {
      let result = message?.data?.result;
      if (result === 1) {
        //  ElMessage({
        //   message: '操作成功.',
        //   type: 'success',
        // })
      }
    };

    //显示配置页面
    const handleShowSetting = () => {
      SettingPageRef.value.show();
    };

    onUnmounted(() => {
      bus.off(`${wsCmd.cmd_20_resp}`);
    });

    onMounted(() => {
      bus.on(`${wsCmd.cmd_20_resp}`, handleUserServiceStatusResp);
    });

    return {
      ...toRefs(data),
      route,
      store,
      userInfo,
      websocketStatus,
      noticeStatus,
      SettingPageRef,
      userServiceStatus,
      handleStatus,
      showUinfoLayer,
      handleSkin,
      onLogout,
      handleLogout,
      handleSideCollapsed,
      handleClickUtil,
      handleShowSetting,
      handleRefresh,
    };
  },
};
</script>


<style lang="scss" scoped>
.ws_connected {
  color: #0aea64 !important;
}
.ws_un_connected {
  color: #e06d6f !important;
}
</style>