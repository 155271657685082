<template>
  <el-card>
    <div>
      <el-form :model="messageSetForm" label-width="auto">
        <el-form-item>
          <template #label>
            人工接管有效时间(单位秒)
            <el-tooltip placement="bottom" effect="light">
              <template #content>
                <div>
                  <p>开启人工接管状态后N秒后自动关闭人工接管状态</p>
                </div>
              </template>

              <i class="iconfont icon-tishi1"></i>
            </el-tooltip>
          </template>

          <el-input v-model="messageSetForm.artificial_active_time" type="number" :min="1" :max="3600" />
        </el-form-item>
        <div style="display: flex; justify-content: flex-end">
          <el-button @click="handleSave" type="primary">保存</el-button>
        </div>
      </el-form>
    </div>
  </el-card>
  <el-card style="margin-top: 10px">
    <template #header>
      平台消息设置
      <el-tooltip class="box-item" placement="top-start">
        <template #content>
          <div>
            1. 控制页面最左侧的平台的显示或隐藏，勾选后代表显示。至少勾选一项
          </div>
          <div>
            2. 通过上移/下移控制显示顺序，默认进入系统选中第一个
          </div>
        </template>
        <i class="iconfont icon-tishi1"></i>
      </el-tooltip>
    </template>
    <div>
      <div class="p_item" v-for="(item, index) in menuListRef" :key="index">
        <el-checkbox v-model="item.checked" :true-value="true" :false-value="false" @change="handleChange(item)">
          <temlat> <i class="iconfont" :class="item.icon"></i>{{ item.desc }}</temlat>
        </el-checkbox>
        <div class="u_d">
          <el-button :link="true" type="primary" :disabled="index == 0" @click="handleUp(item)">
            上移
          </el-button>
          <el-button :link="true" type="primary" :disabled="index == (menuListRef.length - 1)"
            @click="handleDown(item)">
            下移
          </el-button>
        </div>
      </div>
    </div>
  </el-card>
</template>
<script>
import {
  props,
  reactive,
  computed,
  ref,
  inject,
  onMounted,
  nextTick,
  onUnmounted,
} from "vue";
import * as echarts from "echarts";
import { useStore } from "vuex";
import { changeArtificialActiveTime } from "@/api/userapi";
import { ElMessage } from 'element-plus'
export default {
  name: "myleads_today",

  props: { title: { type: String, default: "" } },
  setup(props) {
    const activeTab = inject("activeTab");

    const store = useStore();
    const userInfo = computed(() => {
      return store.state.user.user;
    });

    const menuList = store.state.user.user_plat_msg_menu

    const menuListRef = ref([...menuList])

    let messageSetFormState = {
      artificial_active_time: 0,
    };
    let messageSetForm = reactive({
      ...messageSetFormState,
    });



    // --- 方法 --------

    const handleChange = (v) => {
      if(v.checked===false){
        //检查选中的条数
        let fidx = menuListRef.value.findIndex(item=>item.checked)
        if(fidx <0){
          ElMessage.error('至少显示一个平台')
          v.checked = true
          return
        }
      }
      store.dispatch('user/setPlatmsgMenu', menuListRef.value)
    }
    const handleUp = (item) => {
      let index = menuListRef.value.findIndex(_item => _item.value == item.value)
      if (index <= 0) {
        return
      }
      //上一个元素
      let preItem = menuListRef.value[index - 1]
      menuListRef.value.splice(index - 1, 2, item, preItem)
      store.dispatch('user/setPlatmsgMenu', menuListRef.value)
    }

    const handleDown = (item) => {
      let index = menuListRef.value.findIndex(_item => _item.value == item.value)
      if (index >= menuListRef.value?.length - 1) {
        return
      }
      let nextItem = menuListRef.value[index + 1]
      menuListRef.value.splice(index, 2, nextItem, item)
      store.dispatch('user/setPlatmsgMenu', menuListRef.value)
    }


    const handleSave = () => {
      let data = {
        artificial_active_time: messageSetForm.artificial_active_time,
      };
      changeArtificialActiveTime(data);
    };
    const handleInitPage = () => {
      messageSetForm.artificial_active_time =
        userInfo.value.artificial_active_time;
    };
    onMounted(handleInitPage);
    return {
      messageSetForm,
      userInfo,
      activeTab,
      menuListRef,
      handleSave,
      handleUp,
      handleDown,
      handleChange
    };
  },
};
</script>
<style lang="scss" scoped>
.p_item {
  padding: 5px;
  display: flex;
  align-items: center;

  .u_d {
    margin-left: 2px;
    display: flex;
    align-items: center;
  }
}

.p_item:not(:first-child) {
  border-top: 1px dashed #9ea0a3;

}
</style>