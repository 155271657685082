<!-- //群聊模板 -->
<template>
  <div v-if="selectedAccountFriendId">
    <div style="position: relative">
      <div class="ntMain__head">
        <div class="header_c">
          <div class="left">
            <div class="avatar">
              <img :src="chatingFriendInfo.avatar" />
            </div>
            <div class="name">
              {{ chatingFriendInfo.title }}
            </div>
            <!-- <el-tag type="primary">已加载消息({{ allMsgCount }})</el-tag> -->
          </div>

          <div class="right">
            <el-button
              size="small"
              :class="{
                active_right_comp:
                  activeComponent.component_item == 'chatingCustomInfo',
              }"
              @click="handleActiveSidebarRightComponent('chatingCustomInfo')"
            >
              <el-tooltip content="好友信息" effect="light" placement="right">
                <div class="l_item_tip" style="">
                  <i class="iconfont icon-person"></i>
                </div>
              </el-tooltip>
            </el-button>
            <el-button
              size="small"
              :class="{
                active_right_comp:
                  activeComponent.component_item == 'customChatlogHistory',
              }"
              @click="handleActiveSidebarRightComponent('customChatlogHistory')"
            >
              <el-tooltip content="聊天记录" effect="light" placement="right">
                <div class="l_item_tip">
                  <i class="iconfont icon-rizhi"></i>
                </div>
              </el-tooltip>
            </el-button>
          </div>
        </div>
      </div>
      <div
        style="
          box-sizing: border-box;
          position: absolute;
          width: 100%;
          text-align: center;
        "
      >
        <div v-if="fetchingChatLogFriendId == selectedAccountFriendId">
          <div class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <span
            style="
              color: #9ea0a3;
              font-size: 11px;
              display: inline-block;
              margin-left: 10px;
            "
            >消息加载中...</span
          >
        </div>
        <div v-else style="display: flex; justify-content: center">
          <el-button
            :disabled="chatLogNoMore"
            link
            type="primary"
            @click="handleLoadMore"
            style="font-size: 11px; cursor: pointer; z-index: 999"
            >加载更多>></el-button
          >
        </div>
      </div>
    </div>

    <div
      style="height: 200px"
      class="ntMain__cont flex1 flexbox flex-col"
      @dragenter="handleDragEnter"
      @dragover="handleDragOver"
      @drop="handleDrop"
    >
      <!-- <v3-scroll ref="scrollRef" autohide> -->
      <el-scrollbar ref="scrollRef" @scroll="handleScroll">
        <div ref="innerRef" class="vChatMsg-cnt">
          <div
            style="text-align: center; color: #9ea0a3; font-size: 11px"
            v-if="
              friendChatLog.msglist?.length === 0 &&
              fetchingChatLogFriendId == 0
            "
          >
            ----- 没有消息哦 ----- <br />
            我们友谊从您的第一句招呼开始
          </div>
          <ul class="clearfix">
            <li
              v-for="(item, index) in showChatLogList"
              :key="(item.id || '') + (item.s_uuid || '')"
            >
              <messageItem
                :msgItem="item"
                :upMsgTime="handleFindUpMsgTime(index)"
              />
            </li>
          </ul>
        </div>
        <!-- </v3-scroll> -->
      </el-scrollbar>
    </div>
    <div class="vChat__footTool" style="height: 200px">
      <div class="vChat__editor-panel flexbox flex-col" style="height: 100%">
        <div class="wrap-toolbar">
          <div class="flexbox">
            <div class="flex1">
              <i
                class="btn iconfont icon-emoj"
                title="选择表情"
                @click.stop="handleEmojView"
              ></i>
              <i
                class="btn iconfont icon-tupian"
                title="发送图片"
                @click="handleChooseImageVisiable"
              ></i>
              <!-- AI接管 -->
               <!-- <span v-if="chatingAccount.use_robot_status === 1">
                  <el-popover placement="top-start" width="200" trigger="hover">
                    <div style="min-height: 50px; font-size: 12px">
                      <div class="m_desc" style="margin-bottom: 10px">
                        点击切换为AI接管
                      </div>
                      <div v-if="chatingFriendInfo.is_artificial === 0">
                        当前状态： <span class="m_color_s2">AI接管</span>
                      </div>
                      <div v-else>
                        当前状态： <span class="m_color_s2">人工接管</span>
                      </div>
                    </div>
                    <template #reference>
                      <i
                        @click="handleStartAi(chatingFriendInfo?.cid)"
                        class="btn iconfont icon-zhinengkefuzhongxin"
                        :class="{ m_color_s22: chatingFriendInfo.is_artificial === 0 }"
                      ></i>
                    </template>
                  </el-popover>
               </span> -->
              
              <!-- 人工接管 -->
              <el-popover placement="top-start" width="300" trigger="hover">
                <div style="min-height: 50px; font-size: 12px">
                  <div class="m_desc m_border_b" style="margin-bottom: 10px">
                    <h3 class="m_color_2">点击切换人工接管状态</h3>
                    <h4 class="m_color_2">请注意：人工接管开启后，如果离开当前会话(点击其它好友)，{{ userInfo.artificial_active_time }}秒后【自动关闭】当前好友的人工接管状态</h4>
                    <p class="m_color_s1">1.人工接管状态如果【开启】：Ai消息(如果AI启用)，追粉消息和欢迎语【<span style="font-weight: bold;">不会</span>发送】</p>
                    <p  class="m_color_e">2.人工接管状态如果【关闭】：Ai消息(如果AI启用)，追粉消息和欢迎语【<span style="font-weight: bold;">正常</span>发送】</p>
                    
                  </div>
                 <div v-if="chatingFriendInfo.is_artificial === 1">
                    人工接管状态： <span class="m_color_s1">开启</span>
                  </div>
                  <div v-else>
                    人工接管状态： <span class="m_color_e">关闭</span>
                  </div> 
                </div>
                <template #reference>
                  <i
                    @click="handleChangeManuStatus(chatingFriendInfo?.cid,chatingFriendInfo.is_artificial)"
                    class="btn iconfont icon-male "
                    :class="{ m_color_s22: chatingFriendInfo.is_artificial === 1 }"
                  ></i>
                </template>
              </el-popover>
   
              <!-- <i
                class="btn iconfont icon-zhinengkefuzhongxin m_color_s22"
                title="AI接管"
                @click="handleChooseImageVisiable"
              ></i>
              <i
                class="btn iconfont icon-male"
                title="人工接管"
                @click="handleChooseImageVisiable"
              ></i> -->
              <!-- 图片预览 -->
              <el-dialog
                v-model="preview"
                class="image-preview"
                :before-close="handleClearUploadInfo"
                width="60%"
                top="10vh"
                @open="initUploadDivListener"
              >
                <div>
                  <el-upload
                    :on-exceed="handleExceed"
                    :limit="1"
                    v-model:file-list="uploadFileList"
                    drag
                    :action="qiniuUploadurl"
                    :show-file-list="false"
                    :auto-upload="false"
                    :on-success="handleUploadSuccess"
                    :on-error="handleUploadError"
                    :on-change="handleChooseImage0"
                    :before-upload="handleUploadBefore"
                    :on-progress="hadleUploadProgress"
                    :accept="pictureType"
                    ref="uploadPictureRef"
                    style="display: inline-block; width: 100%"
                    :data="uploadImageData"
                  >
                    <el-icon class="el-icon--upload"><upload-filled /></el-icon>
                    <div class="el-upload__text">
                      拖拽文件到此处或 <em>点击此处上传</em>
                    </div>
                  </el-upload>
                </div>

                <div
                  style="
                    height: 200px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <div
                    style="
                      border: 1px dashed red;
                      width: 100%;
                      height: 100%;
                      position: relative;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    支持<em style="color: #3dbb51">截屏粘贴图片</em>到此处上传
                    <div
                      style="
                        position: absolute;
                        top: 0;
                        background-color: rgba(0, 0, 0, 0.1);
                      "
                      ref="uploadImageDivRef"
                      @keydown="handleUploadImageDivInput"
                      class="editor_div"
                      v-html="uploadImageText"
                      contentEditable="true"
                    ></div>
                  </div>
                </div>

                <div
                  style="
                    display: flex;
                    justify-content: center;
                    margin-top: 10px;
                  "
                >
                  <el-button
                    type="primary"
                    v-loading="imageSendLoading"
                    @click="handleSendImage"
                    >发送</el-button
                  >
                  <el-button @click="handleClearUploadInfo">取消</el-button>
                </div>
              </el-dialog>

              <!-- <i class="btn iconfont icon-paizhao" title="发送视频"
                ><input
                  ref="pickVideoRef"
                  type="file"
                  accept="video/*"
                  @change="handleChooseVideo"
              /></i> -->
              <!-- <i class="btn iconfont icon-yuyin" title="发送语音"></i> -->
              <!-- <i
                class="btn iconfont icon-jietu"
                title="截图"
                @click="handleScreenShot"
              ></i> -->
              <!-- <i
                class="btn iconfont icon-shake"
                title="向好友发送抖动窗口"
                @click="handleScreenShake"
              ></i> -->
              <!-- <i
                class="btn iconfont icon-redpacket"
                title="发红包"
                @click="isShowSendRedPacketLayer = true"
              ></i> -->
            </div>
            <i
              class="btn iconfont icon-gonggao"
              title="截屏、截图可直接粘贴至编辑框发送"
              @click="handleTipsLayer"
            ></i>
          </div>
          <div v-show="showEmojView" class="wrap-emojPanel" @click.stop>
            <div class="wrap-emojbox">
              <div class="emoj__cells">
                <v3-scroll size="5">
                  <div>
                    <div :class="'face__sm_list'">
                      <div
                        v-for="(item, index) in emojList"
                        :key="index"
                        class="item"
                      >
                        <img
                          :src="require('@assets/img/emoji/' + item.file)"
                          class="face"
                          @click.stop="handleAddFace(item)"
                        />
                        <!-- <img
                          v-else
                          :src="item2"
                          @click="handleEmojGifClicked(item2)"
                        /> -->
                      </div>
                    </div>
                  </div>
                </v3-scroll>
              </div>
            </div>
          </div>
        </div>
        <div class="wrap-editor flex1">
          <textarea
            ref="editorRef"
            @keydown="handleOnKeyup"
            v-model="editorText"
            @blur="handleSaveFocusIndex"
            name="textarea"
            style="width: 100%; height: 100%; border: none"
            maxlength="1024"
            @keydown.enter="handleMsgTextEvent($event)"
          ></textarea>
        </div>

        <el-button
          v-if="(chatingAccount?.end_flg === 1 || chatingAccount?.end_flg === -1) && chatingPlatId == 1"
          type="danger"
          :disabled="true"
        >
          【{{ chatingAccount.username }}】的端口已过期或未绑定无法发送信息
        </el-button>
        <button
          v-else
          class="btn-submit"
          :disabled="isSubmitDisabled"
          @click="handleSubmit"
        >
          发送(S)
        </button>
      </div>
    </div>

    <!-- 播放器模板 -->
    <!-- <v3-layer
      v-model="isShowVideoPlayer"
      title="<i class='iconfont icon-bofang'></i> 视频预览"
      layerStyle="background:#f9f9f9"
      opacity=".2"
      :area="['550px', '450px']"
      xclose
      resize
      :maximize="true"
    >
      <video
        class="vplayer"
        ref="playerRef"
        :src="videoList.videosrc"
        :poster="videoList.imgsrc"
        autoplay
        preload="auto"
        controls
        x5-video-player-fullscreen="true"
        webkit-playsinline="true"
        x-webkit-airplay="true"
        playsinline="true"
        x5-playsinline
        style="height: 100%; width: 100%; object-fit: contain; outline: none"
      />
    </v3-layer> -->
    <!-- 红包模板 -->
    <!-- <v3-layer
      v-model="isShowRedPacket"
      layerStyle="background-image:linear-gradient(180deg, #ff8952 10%, #ff5722);"
      opacity=".2"
      :area="['320px', '400px']"
      xclose
      xcolor="#ffdccb"
      drag=".ntDrag__head"
    >
      
    </v3-layer> -->
    <!-- 发红包弹窗模板 -->
    <!-- <v3-layer
      v-model="isShowSendRedPacketLayer"
      layerStyle="background:#fff8f4"
      opacity=".2"
      :area="['400px', '480px']"
      xclose
      xcolor="#ffdccb"
      drag=".ntDrag__head"
    >
      <SendRedPacket />
    </v3-layer> -->
    <!-- 设置弹窗模板 -->
    <!-- <v3-layer
      v-model="isShowGroupSetLayer"
      title="<div style='padding-left:36px;text-align:center;'>群设置</div>"
      opacity=".2"
      area="250px"
      drawer="right"
      xclose
      xposition="left"
    >
      <GroupSet />
    </v3-layer> -->
  </div>
</template>
<script>
import {
  onMounted,
  ref,
  reactive,
  toRefs,
  watch,
  nextTick,
  inject,
  computed,
  onUnmounted,
} from "vue";
import { useRoute } from "vue-router";
// import Editor from "./editor.vue";
// import SendRedPacket from "./redPacket.vue";
// import GroupSet from "./groupInfo.vue";
import { useStore } from "vuex";
import messageItem from "@/views/components/messageItem.vue";
import {
  getFriendChatLog,
  notifyFriendMsgReaded,
  getFriendClue,
  sendMsg,
} from "@/api/chatapi";
import { v4 as uuidv4 } from "uuid";
// const emoJSON = require("./mock/emoj.js");
import { emojis } from "@/utils/emoji-util";
//const msgJSON = require("@/mock/recordlist.js");
// import ImagePreview from './components/ImagePreview'
import { ElMessage } from "element-plus";
import { UploadFilled } from "@element-plus/icons-vue";
import {changeFriendAiStatus} from '@/api/aichatapi'
const DEFAULT_SHOW_NUM = 50;
const FETAH_SIZE = 50;
export default {
  components: { messageItem, UploadFilled },
  setup() {
    const innerRef = ref(null);
    const scrollRef = ref(null);
    const uploadPictureRef = ref(null);
    const editorRef = ref(null);
    const uploadImageDivRef = ref(null);
    const pickImageRef = ref(null);
    const pickVideoRef = ref(null);
    const playerRef = ref(null);
    const route = useRoute();
    const v3layer = inject("v3layer");
    const bus = inject("$eventBus");
    const allMsgCount = ref(0);
    const showMsgCount = ref(DEFAULT_SHOW_NUM);

    const wsCmd = inject("wsCmd");
    const eventbusCms = inject("eventbusCms");
    const chatlogstore = inject("chatlogstore");

    let store = useStore();
    const userInfo = computed(() => {
      return store.state.user.user;
    });

    const data = reactive({
      focusIndex: 0, // 光标在文本的位置
      editorText: "",
      showEmojView: false,
      isSubmitDisabled: true,
      emojList: emojis,
      groupLs: null,
      // 视频播放器
      isShowVideoPlayer: false,
      videoList: [],
      // 開红包弹窗
      isShowRedPacket: false,
      redPacketList: [],
      // 发送红包弹窗
      isShowSendRedPacketLayer: false,
      // 群设置
      isShowGroupSetLayer: false,
      //聊天信息
      friendChatLogData: { fid: 0, aid: 0, msglist: [] },
      //消息滚动条滚动到顶部
      scrollToTop: false,
      //没有更多消息
      chatLogNoMore: false,
      //图片预览
      preview: false,
      previewImg: "",
      //1图片
      previewType: 1,
      progress: 100,
      pictureType: ".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP",
      // 最大图片上传尺寸
      maxPictureSize: 2,
      //图片发送加载
      imageSendLoading: false,
      //图片上传内容
      uploadImageText: "",
      uploadFileList: [],
    });

    const qiniuUploadurl = computed(() => {
      return store.state.qiniu.uploadurl;
    });

    // 激活显示的右侧组件
    const activeComponent = computed(() => {
      return store.state.sidebarRight.activeComponent;
    });

    onUnmounted(() => {
      //注册监听事件start---
      bus.off(`${wsCmd.cmd_4_resp}`); // listen
      bus.off(`${wsCmd.cmd_6_resp}`); // listen
      bus.off(eventbusCms.selectFriendChange); // listen
      bus.off(eventbusCms.sendFriendMsg);
      //注册监听事件end---
    });

    onMounted(() => {
      //注册监听事件start---
      bus.on(`${wsCmd.cmd_4_resp}`, receiveFrindChatLog); // listen
      bus.on(`${wsCmd.cmd_6_resp}`, receiveFrindChatLog); // listen
      bus.on(eventbusCms.selectFriendChange, onSelectFriendChange); // listen
      bus.on(eventbusCms.sendFriendMsg, handleSendMessage);
      //注册监听事件end---

      let cid = route.query.id;
      if (cid) {
        initAndFetchChatLog(cid);
      }
    });
    watch(
      () => data.editorText,
      () => {
        if (isEmpty(data.editorText)) {
          data.isSubmitDisabled = true;
        } else {
          data.isSubmitDisabled = false;
        }
      }
    );

    //开启好友AI接管
    const handleStartAi = (chatFriendId)=>{
      if(!chatFriendId){
        return
      }
      let param = {fid:chatFriendId,is_artificial:0}
      changeFriendAiStatus(param)
    }

    // 停止好友AI接管
    const handleChangeManuStatus = (chatFriendId,status)=>{
      if(!chatFriendId){
        return
      }
      let _status = status === 1?0:1
      let param = {fid:chatFriendId,is_artificial:_status}
      changeFriendAiStatus(param)
    }


    const handleUseRobotStatusChange = ()=>{

    }
    //加载更多
    const handleLoadMore = () => {
      //获取最小的记录ID
      let minId = getMinChatLogId(showChatLogList.value);
      getFriendChatLog({
        fid: selectedAccountFriendId.value, //好友id
        limit: FETAH_SIZE, //返回记录数，默认20
        log_id: minId, //上页记录id
      });
      showMsgCount.value = showMsgCount.value + FETAH_SIZE;
    };

    //消息滚动条滚动事件处理
    const handleScroll = (e) => {
      //暂时不处理滚动刷新事件
      let i = 1;
      if (i == 1) {
        return;
      }

      let scrollTop = e.scrollTop;
      //滚动到顶部加载上一页
      if (scrollTop == 0 && data.chatLogNoMore == false) {
        data.scrollToTop = true;
        //获取最早一条聊天记录ID
        //本地数据
        let localData = chatlogstore.getChatLog(selectedAccountFriendId.value);
        let minId = 0;
        if (localData && localData.msglist?.length > 0) {
          minId = getMinChatLogId(localData.msglist);
        }
        //当前聊天数据
        if (
          data.friendChatLogData.msglist &&
          data.friendChatLogData.msglist.length > 0
        ) {
          let pageMinId = getMinChatLogId(data.friendChatLogData.msglist);
          if (pageMinId < minId) {
            minId = pageMinId;
          }
        }
        getFriendChatLog({
          fid: selectedAccountFriendId.value, //好友id
          limit: FETAH_SIZE, //返回记录数，默认20
          log_id: minId, //上页记录id
        });
      }
    };

    //获取最小的聊天消息ID
    function getMinChatLogId(msgList) {
      if (!msgList || msgList.length == 0) {
        return 0;
      }
      let minId = 0;
      for (var i = 0; i < msgList.length; i++) {
        let item = msgList[i];
        //排除时间类型
        if (item.msgtype == 1) {
          continue;
        }
        minId = item.id;
        break;
      }

      return minId;
    }
    //当前选中好友
    const selectedAccountFriendId = computed(() => {
      let chatLink = store.state.wsData.accountFriendActiveMap.find(
        (item) => item.chatingAccountId == selectedAccountId.value
      );
      let chatingFriendId =
        store.state.wsData.chatingFriendId || chatLink?.chatingFriendId;
      return chatingFriendId;
    });
    //接口正在请求的聊天记录好友ID
    const fetchingChatLogFriendId = computed(() => {
      return store.state.wsData.fetchingChatLogFriendId;
    });

    //当前聊天账号
    const chatingAccount = computed(() => {
      return store.state.wsData.chatingAccountInfo;
    });

     //当前选中平台
     const chatingPlatId = computed(() => {
      return store.state.wsData.chatingPlatId
    });

    //当前选中账号
    const selectedAccountId = computed(() => {
      let chatLink = store.state.wsData.platAccountActiveMap.find(
        (item) => item.chatingPlatId == store.state.wsData.chatingPlatId
      );
      let chatingAccountId =
        store.state.wsData.chatingAccountId || chatLink?.chatingAccountId;
      return chatingAccountId;
    });

    //当前聊天好友
    const chatingFriendInfo = computed(() => {
      return store.state.wsData.chatingFriendInfo;
    });

    //好友聊天记录
    const friendChatLog = computed(() => {
      if (data.friendChatLogData.fid != selectedAccountFriendId.value) {
        return { fid: 0, aid: 0, msglist: [] };
      }
      return data.friendChatLogData;
    });

    //显示消息列表
    const showChatLogList = computed(() => {
      if (data.friendChatLogData.fid != selectedAccountFriendId.value) {
        return { fid: 0, aid: 0, msglist: [] };
      }
      let allMsgList = data.friendChatLogData.msglist;
      //handleSetAllMsgCount(allMsgList.length)
      if (allMsgList?.length > showMsgCount.value) {
        return allMsgList.slice(showMsgCount.value * -1);
      } else {
        return allMsgList;
      }
    });

    //设置总消息条数
    const handleSetAllMsgCount = (num) => {
      allMsgCount.value = num;
      if (showMsgCount.value > num) {
        showMsgCount.value = num;
      }
    };

    // //好友聊天记录
    // const chatingAccount = computed(() => {
    //   return store.state.chat.frindChatLog;
    // });

    function receiveFrindChatLog(frindChatLogResp) {
      if (frindChatLogResp && frindChatLogResp.code === 1) {
        let respData = frindChatLogResp.data;
        if (respData?.msglist?.length > 0) {
          //存入缓存,如果有数据
          chatlogstore.refreshChatLogStoreAsyn(frindChatLogResp).then(() => {
            //通知消息变更
            bus.emit(
              eventbusCms.chatLogListChange,
              selectedAccountFriendId.value
            );
          });
        }

        //消息所属好友ID
        let cid = frindChatLogResp.data?.fid;
        if (cid === selectedAccountFriendId.value) {
          store.commit("wsData/SET_FETCHING_CHATLOG_FRIENDID", 0);
          //是否有更多数据
          if (respData.msglist?.length == 0) {
            //没有更多数据了
            data.chatLogNoMore = true;
            //通知没有更多
            bus.emit(
              eventbusCms.chatLogListNomore,
              selectedAccountFriendId.value
            );
            //10秒后释放
            //  setTimeout(() => {
            //   data.chatLogNoMore = false
            //  }, 10000);
          }

          data.friendChatLogData.aid = respData.aid;
          data.friendChatLogData.fid = respData.fid;
          //获取聊天记录,并合并
          chatlogstore.refreshChatMessage(
            data.friendChatLogData.msglist,
            respData.msglist,
            frindChatLogResp.type
          );

          data.friendChatLogData.msglist.push({ temp: true });
          //页面不刷新临时解决办法
          let delteIndex = data.friendChatLogData.msglist.findIndex(
            (item) => item.temp == true
          );
          data.friendChatLogData.msglist.splice(delteIndex, 1);

          //data.friendChatLogData.msglist = mergedChatLogMsgList;
          setTimeout(() => {
            nextTick(() => {
              imgLoaded(scrollRef);
            });
          }, 10);
        }
      }
    }

    //选中好友变动
    function onSelectFriendChange(cid) {
      data.chatLogNoMore = false;
      initAndFetchChatLog(cid);
      //重新初始化页面显示条数
      showMsgCount.value = DEFAULT_SHOW_NUM;
    }

    //获取聊天数据
    function initAndFetchChatLog(cid) {
      //先本地初始化
      initChatLogFromLocalStoreMsg(cid);
      //获取消息
      getFriendChatLog({
        fid: cid, //好友id
        limit: FETAH_SIZE, //返回记录数，默认20
        log_id: 0, //上页记录id
      });
      //获取好友的线索信息
      getFriendClue({ fid: cid });
      //通知后台消息标记为已读
      notifyFriendMsgReaded({
        fid: cid, //好友id
      });
    }

    //从本地初始化聊天记录
    function initChatLogFromLocalStoreMsg(fid) {
      let localData = chatlogstore.getChatLog(fid);
      if (!localData) {
        data.friendChatLogData.msglist = [];
      } else {
        data.friendChatLogData.fid = localData.fid;
        data.friendChatLogData.aid = localData.aid;
        data.friendChatLogData.msglist = localData.msglist;
      }
    }

    const preloadImages = (arr) => {
      let loadedCount = 0;
      let imgs = [];
      return new Promise(function (resolve, reject) {
        for (let i = 0; i < arr.length; i++) {
          imgs[i] = new Image();
          imgs[i].src = arr[i];
          imgs[i].onload = function () {
            loadedCount++;
            if (loadedCount == arr.length) {
              resolve();
            }
          };
          imgs[i].onerror = function () {
            reject();
          };
        }
      });
    };
    /**
     * 图片加载完成，聊天信息滚到最底部
     */
    const imgLoaded = (ref) => {
      //scrollBottom(ref);
      let msgBox = ref.value?.$el;
      if (msgBox) {
        let imgs = msgBox.querySelectorAll("img");
        if (imgs) {
          let arr = [];
          for (let i = 0; i < imgs.length; i++) {
            arr[i] = imgs[i].src;
          }
          preloadImages(arr)
            .then(() => {
              scrollBottom(ref);
            })
            .catch(function () {
              scrollBottom(ref);
            });
        }
      }
    };
    /** 滚动条到底部
     */
    const scrollBottom = (ref) => {
      let viewport = ref.value;
      //如果是滚动到顶部事件触发的就不滚动到底部了
      if (data.scrollToTop) {
        data.scrollToTop = false;
        viewport.setScrollTop(100);
        return;
      }
      if (viewport) {
        viewport.setScrollTop(innerRef.value?.clientHeight);
      }
    };
    const handleMsgClicked = (e) => {
      let target = e.target;
      if (target.tagName === "A") {
        e.preventDefault();
        v3layer({
          type: "iframe",
          title: '<i class="iconfont icon-link"></i> 网址预览',
          content: target.href,
          opacity: 0.2,
          area: ["860px", "600px"],
          xclose: true,
          resize: true,
          maximize: true,
        });
      }
      // 图片
      if (target.tagName === "IMG" && target.classList.contains("img-view")) {
        //  ...
      }
    };
    // 显示表情框
    const handleEmojView = () => {
      data.showEmojView = true;
      function hideEmojView() {
        data.showEmojView = false;
        document.body.removeEventListener("click", hideEmojView);
      }
      document.body.addEventListener("click", hideEmojView);
    };
    // 表情Tab切换
    const handleEmojTab = (index) => {
      let emojLs = data.emojList;
      for (var i = 0, len = emojLs.length; i < len; i++) {
        emojLs[i].selected = false;
      }
      emojLs[index].selected = true;
      data.emojList = emojLs;
    };
    const handleEditorClick = () => {
      // console.log('点击编辑器')
      data.isShowFootBar = false;
    };
    // 编辑器获取焦点
    const handleEditorFocus = () => {
      // console.log('编辑器获取焦点')
    };
    // 编辑器失去焦点
    const handleEditorBlur = () => {
      // console.log('编辑器失去焦点')
    };
    const handleEditorPaste = (img) => {
      let msgLs = data.groupLs.msglist;
      let len = msgLs.length;
      // 消息队列
      let arrLS = {
        id: `msg${++len}`,
        msgtype: 5,
        isme: true,
        //avatar: require("@assets/img/uimg/img-avatar01.jpg"),
        author: "Andy",
        msg: "",
        imgsrc: img,
        videosrc: "",
      };
      msgLs = msgLs.concat(arrLS);
      data.groupLs.msglist = msgLs;
      nextTick(() => {
        imgLoaded(scrollRef);
      });
    };
    // 点击表情
    const handleEmojClicked = (e) => {
      let faceimg = e.target.cloneNode(true);
      editorRef.value.insertHtmlAtCursor(faceimg);
      data.showEmojView = false;
    };
    const handleEmojGifClicked = (path) => {
      let msgLs = data.groupLs.msglist;
      let len = msgLs.length;
      // 消息队列
      let arrLS = {
        id: `msg${++len}`,
        msgtype: 4,
        isme: true,
        //avatar: require("@assets/img/uimg/img-avatar01.jpg"),
        author: "Andy",
        msg: "",
        imgsrc: path,
        videosrc: "",
      };
      msgLs = msgLs.concat(arrLS);
      data.groupLs.msglist = msgLs;
      data.showEmojView = false;
      nextTick(() => {
        imgLoaded(scrollRef);
      });
    };
    // 发送消息
    const isEmpty = (html) => {
      html = html.replace(/<br[\s/]{0,2}>/gi, "\r\n");
      html = html.replace(/<[^img].*?>/gi, "");
      html = html.replace(/&nbsp;/gi, "");
      return (
        html
          .replace(/\r\n|\n|\r/, "")
          .replace(/(?:^[ \t\n\r]+)|(?:[ \t\n\r]+$)/g, "") == ""
      );
    };
    const transferHTML = (html) => {
      let reg = /(http:\/\/|https:\/\/)((\w|#|=|\?|\.|\/|&|-)+)/g;
      return html.replace(reg, "<a href='$1$2'>$1$2</a>");
    };

    const handleMsgTextEvent = async (event) => {
      if (event.keyCode == 13) {
        if (!event.ctrlKey) {
          event.preventDefault();
          handleSubmit();
        } else {
          data.editorText += "\n";
        }
      }
    };

    //发送消息
    const handleSendMessage = (simpleMessageInfo) => {
      // console.log('-------------------------handleSendMessage',simpleMessageInfo)
      let sendMsgItem = {
        msgtype: simpleMessageInfo.msgtype,
        fid: selectedAccountFriendId.value,
        avatar: chatingAccount.value.avatar,
        author: chatingAccount.value.username,
        send_status: 2,
        isme: true,
        s_uuid: simpleMessageInfo.s_uuid,
      };
      //消息类型:1 时间类型，2 通知类型，3 文本消息 ，5图片
      if (simpleMessageInfo.msgtype == 3) {
        sendMsgItem.msg = simpleMessageInfo.value;
      } else if (simpleMessageInfo.msgtype == 5) {
        sendMsgItem.msg = simpleMessageInfo.value;
        sendMsgItem.imgsrc = simpleMessageInfo.value;
      }
      //发送消息
      sendMsg(sendMsgItem);
      //添加到本地消息
      data.friendChatLogData.msglist.push(sendMsgItem);
      chatlogstore.pushSendMsgeStore(sendMsgItem);
      nextTick(() => {
        imgLoaded(scrollRef);
      });
    };

    const handleSubmit = () => {
      if (isEmpty(data.editorText)) return;
      // let arrLS = {
      //   id: `msg${++len}`,
      //   msgtype: 3,
      //   isme: true,
      //   avatar: require("@assets/img/uimg/img-avatar01.jpg"),
      //   author: "Andy",
      //   msg: transferHTML(data.editorText),
      //   imgsrc: "",
      //   videosrc: "",
      // };

      let sendMsgData = {
        fid: selectedAccountFriendId.value,
        avatar: chatingAccount.value.avatar,
        author: chatingAccount.value.username,
        msgtype: 3,
        send_status: 2,
        isme: true,
        msg: transferHTML(data.editorText),
        s_uuid: uuidv4(),
      };
      //发送消息
      sendMsg(sendMsgData);
      //添加到本地消息
      data.friendChatLogData.msglist.push(sendMsgData);
      chatlogstore.pushSendMsgeStore(sendMsgData);

      // 清空文本框内容
      nextTick(() => {
        data.editorText = "";
        editorRef.value.focus();
      });
      nextTick(() => {
        imgLoaded(scrollRef);
      });
    };

    // 选择视频
    const handleChooseVideo = () => {
      let msgLs = data.groupLs.msglist;
      let len = msgLs.length;
      let arrLS = {
        id: `msg${++len}`,
        msgtype: 6,
        isme: true,
        //avatar: require("@assets/img/uimg/img-avatar01.jpg"),
        author: "Andy",
        msg: "",
        imgsrc: "",
        videosrc: "",
      };
      let file = pickVideoRef.value.files[0];
      if (!file) return;
      let size = Math.floor(file.size / 1024);
      if (size > 5 * 1024) {
        v3layer({ content: "请选择5MB以内的视频！" });
        return false;
      }
      // 获取视频地址
      let videoUrl;
      if (window.createObjectURL != undefined) {
        videoUrl = window.createObjectURL(file);
      } else if (window.URL != undefined) {
        videoUrl = window.URL.createObjectURL(file);
      } else if (window.webkitURL != undefined) {
        videoUrl = window.webkitURL.createObjectURL(file);
      }
      let $video = document.createElement("video");
      $video.src = videoUrl;
      $video.autoplay = true;
      $video.play();
      $video.muted = true;
      $video.addEventListener("timeupdate", () => {
        if ($video.currentTime > 0.1) {
          $video.pause();
        }
      });
      $video.addEventListener("loadeddata", function () {
        setTimeout(() => {
          var canvas = document.createElement("canvas");
          canvas.width = $video.videoWidth * 0.8;
          canvas.height = $video.videoHeight * 0.8;
          canvas
            .getContext("2d")
            .drawImage($video, 0, 0, canvas.width, canvas.height);
          arrLS.imgsrc = canvas.toDataURL("image/png");
          arrLS.videosrc = videoUrl;
          msgLs = msgLs.concat(arrLS);
          data.groupLs.msglist = msgLs;

          nextTick(() => {
            imgLoaded(scrollRef);
          });
        }, 16);
      });
    };
    // 播放视频
    const handleVideoPlayed = (item) => {
      data.isShowVideoPlayer = true;
      data.videoList = item;
      nextTick(() => {
        playerRef.value.play();
      });
    };
    // 右键：消息
    const handleMsgCtxMenu = (e, index) => {
      let points = [e.clientX, e.clientY];
      //let msgLs = data.groupLs.msglist;
      console.log(index);
      let $el = v3layer({
        type: "contextmenu",
        opacity: 0,
        follow: points,
        btns: [
          { text: "复制" },
          { text: "发送给好友" },
          { text: "转发" },
          { text: "收藏" },
          {
            text: "删除",
            click: () => {
              // msgLs.splice(index, 1);
              // data.groupLs.msglist = msgLs;
              $el.close();
            },
          },
        ],
      });
    };
    const handleDragEnter = (e) => {
      e.stopPropagation();
      e.preventDefault();
    };
    const handleDragOver = (e) => {
      e.stopPropagation();
      e.preventDefault();
    };
    const handleDrop = (e) => {
      e.stopPropagation();
      e.preventDefault();
      handleFileList(e.dataTransfer);
    };
    // 获取拖拽文件列表
    const handleFileList = (filelist) => {
      let files = filelist.files;
      if (files.length >= 2) {
        v3layer.message({
          icon: "error",
          content: "暂时支持拖拽一张图片",
          shade: true,
          layerStyle: { background: "#ffefe6", color: "#ff3838" },
        });
        return false;
      }
      for (let i = 0; i < files.length; i++) {
        if (files[i].type != "") {
          handleFileAdd(files[i]);
        } else {
          v3layer.message({
            icon: "error",
            content: "目前不支持文件夹拖拽功能",
            shade: true,
            layerStyle: { background: "#ffefe6", color: "#ff3838" },
          });
        }
      }
    };
    const handleFileAdd = (file) => {
      let msgLs = data.groupLs.msglist;
      let len = msgLs.length;
      let arrLS = {
        id: `msg${++len}`,
        msgtype: 5,
        isme: true,
        avatar: require("@assets/img/uimg/img-avatar08.jpg"),
        author: "Andy",
        msg: "",
        imgsrc: "",
        videosrc: "",
      };
      if (file.type.indexOf("image") == -1) {
        v3layer.message({
          icon: "error",
          content: "目前不支持非图片拖拽功能",
          shade: true,
          layerStyle: { background: "#ffefe6", color: "#ff3838" },
        });
      } else {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          let img = this.result;
          arrLS.imgsrc = img;
          msgLs = msgLs.concat(arrLS);
          data.groupLs.msglist = msgLs;
          nextTick(() => {
            imgLoaded(scrollRef);
          });
        };
      }
    };
    const handleNoticeLayer = () => {
      v3layer({
        title: '<i class="iconfont icon-gonggao1"></i> 群公告',
        content:
          '<div class="pad30">各位进群后，记得修改群昵称。格式: 职位+昵称。群共享文件专区资源大家可以去下载。</div>',
        anim: "fadeInDown",
        position: "rb",
        area: "300px",
        xclose: true,
      });
    };
    // 提示信息
    const handleTipsLayer = (e) => {
      let pos = [e.clientX + 25, e.clientY - 110];
      v3layer.popover({
        icon: "info",
        title: "Tips",
        content:
          '<div class="pb-10">发送消息快捷键:<b class="bg-00e077 c-fff">Enter</b> <br/> 消息换行快捷键 <b class="bg-00e077 c-fff">Ctrl+Enter</b> </div>',
        follow: pos,
        shade: true,
        opacity: 0.2,
      });
    };
    // 红包弹窗
    const handleRedpacketLayer = (item) => {
      data.isShowRedPacket = true;
      data.redPacketList = item;
    };
    const handleOpenRedPacket = () => {
      let btnOpen = document.querySelector(".btn__open");
      btnOpen.classList.add("active");
      setTimeout(() => {
        btnOpen.classList.remove("active");
        data.isShowRedPacket = false;
      }, 1000);
    };
    const handleScreenShot = () => {};

    // 窗口抖动
    const handleScreenShake = () => {
      let ntbox = document.querySelector(".vui__wrapper");
      ntbox.classList.add("shake");
      setTimeout(() => {
        ntbox.classList.remove("shake");
      }, 1000);
    };

    //选中表情
    const handleAddFace = (item) => {
      let content = data.editorText;
      if (content) {
        let oldContentLength = content.length;
        content = `${content.substring(0, data.focusIndex)}[${
          item.title
        }]${content.substring(data.focusIndex, content.length)}`;
        data.focusIndex += content.length - oldContentLength;
      } else {
        content = `[${item.title}]`;
        data.focusIndex += content.length;
      }
      data.editorText = content;
    };

    const handleSaveFocusIndex = () => {
      data.focusIndex = editorRef.value.selectionStart;
    };

    // 按 CTRL + 回车发送信息
    const handleOnKeyup = (e) => {
      // var keyCode = e.keyCode || e.which;
      // if (e.ctrlKey && keyCode === 13) {
      //   handleSubmit();
      //   e.preventDefault();
      //   return false;
      // }
    };

    //显示图片上传窗口
    const handleChooseImageVisiable = () => {
      //console.log(data.previewImg)
      data.preview = true;

      //立即执行token刷新
      store.dispatch("qiniu/refreshToken");
    };

    //选中图片
    const handleChooseImage0 = (file) => {
      data.previewImg = URL.createObjectURL(file.raw);
      let img =
        '<img src="' +
        data.previewImg +
        '" alt="" style="vertical-align: middle; width: 200px; height: 200px" />';
      data.uploadImageText = img;
    };

    //清理文件上传信息
    const handleClearUploadInfo = () => {
      data.imageSendLoading = false;
      data.preview = false;
      data.previewImg = "";
      data.uploadFileList = [];
      uploadImageDivRef.value.innerHTML = "";
    };

    const handleUploadBefore = (file) => {
      data.imageSendLoading = false;
      let tokenInfo = store.getters["qiniu/getTokenInfo"];
      if (!tokenInfo?.token) {
        store.dispatch("qiniu/refreshToken");
        ElMessage.error("图片服务器繁忙，请稍后重试");
        data.preview = false;
        data.previewImg = "";
        return false;
      }

      data.progress = 0;
      if (data.maxPictureSize * 1024 * 1024 < file.size) {
        ElMessage.error("上传图片大小不能超过 " + data.maxPictureSize + "MB!");
        data.preview = false;
        data.previewImg = "";
        return false;
      }
      return true;
    };

    const hadleUploadProgress = (e) => {
      data.progress = e.percent;
    };

    //发送图片
    const handleSendImage = () => {
      if (!data.previewImg) {
        ElMessage.warning("请选择图片");
        return;
      }
      data.imageSendLoading = true;
      uploadPictureRef.value.submit();
    };
    //发送图片数据
    const uploadImageData = computed(() => {
      return { token: store.state.qiniu.token };
    });

    //发送图片失败
    const handleUploadError = (err) => {
      console.log(err.message);
      let msg = JSON.parse(err.message);
      ElMessage.error(msg.error);
      data.imageSendLoading = false;
      nextTick(() => {
        handleClearUploadInfo();
      });
    };
    //发送图片成功
    const handleUploadSuccess = (res) => {
      nextTick(() => {
        handleClearUploadInfo();
      });

      let domain = store.state.qiniu.domain;
      if (domain?.endsWith("/")) {
        domain = domain.substring(0, domain.length - 1);
      }
      let key = res.key;
      if (key?.startsWith("/")) {
        key = key.substring(1);
      }

      let url = domain + "/" + key;
      let sendImageDAta = {
        fid: selectedAccountFriendId.value,
        msgtype: 5,
        send_status: 2,
        isme: true,
        avatar: chatingAccount.value.avatar,
        author: chatingAccount.value.username,
        msg: url,
        imgsrc: url,
        videosrc: "",
        s_uuid: uuidv4(),
      };

      //添加到本地消息
      data.friendChatLogData.msglist.push(sendImageDAta);
      chatlogstore.pushSendMsgeStore(sendImageDAta);

      //发送消息
      sendMsg(sendImageDAta);
      nextTick(() => {
        imgLoaded(scrollRef);
      });
    };

    const initUploadDivListener = () => {
      uploadImageDivRef.value.addEventListener("paste", function (e) {
        e.preventDefault();
        let cbd = e.clipboardData;
        let ua = window.navigator.userAgent;
        if (!(e.clipboardData && e.clipboardData.items)) return;
        if (
          cbd.items &&
          cbd.items.length === 2 &&
          cbd.items[0].kind === "string" &&
          cbd.items[1].kind === "file" &&
          cbd.types &&
          cbd.types.length === 2 &&
          cbd.types[0] === "text/plain" &&
          cbd.types[1] === "Files" &&
          ua.match(/Macintosh/i) &&
          Number(ua.match(/Chrome\/(\d{2})/i)[1]) < 49
        ) {
          return;
        }
        for (var i = 0; i < cbd.items.length; i++) {
          var item = cbd.items[i];
          if (item.kind == "file") {
            var file = item.getAsFile();
            if (file.size === 0) return;
            // var reader = new FileReader();
            // reader.readAsDataURL(blob);
            data.uploadImageText = "";

            uploadPictureRef.value?.clearFiles();
            file.uid = new Date().getTime();
            uploadPictureRef.value?.handleStart(file);
            return false;
          }
        }
      });
    };
    //文件上传超出限制处理
    const handleExceed = (files) => {
      uploadPictureRef.value?.clearFiles();
      const file = files[0];
      file.uid = new Date().getTime();
      uploadPictureRef.value?.handleStart(file);
    };

    const handleUploadImageDivInput = () => {};

    //查找上一条聊天记录的聊天时间
    const handleFindUpMsgTime = (index) => {
      if (index == 0) {
        return "";
      }
      let item = friendChatLog.value.msglist[index - 1];
      return item?.send_time || "";
    };

    // 激活右侧窗口----------------------
    const handleActiveSidebarRightComponent = (componentStr) => {
      store.dispatch("sidebarRight/activeComponent", componentStr);
    };

    return {
      ...toRefs(data),
      chatingPlatId,
      innerRef,
      scrollRef,
      editorRef,
      uploadImageDivRef,
      pickImageRef,
      pickVideoRef,
      playerRef,
      chatingAccount,
      selectedAccountId,
      selectedAccountFriendId,
      chatingFriendInfo,
      friendChatLog,
      fetchingChatLogFriendId,
      qiniuUploadurl,
      uploadImageData,
      uploadPictureRef,
      showChatLogList,
      activeComponent,
      allMsgCount,
      showMsgCount,
      userInfo,
      initUploadDivListener,
      handleMsgClicked,
      handleEmojView,
      handleEmojTab,
      handleEditorClick,
      handleEditorFocus,
      handleEditorBlur,
      handleEditorPaste,
      handleEmojClicked,

      handleEmojGifClicked,
      handleMsgTextEvent,
      handleSubmit,
      handleChooseVideo,
      handleVideoPlayed,
      handleMsgCtxMenu,
      handleNoticeLayer,
      handleTipsLayer,
      handleRedpacketLayer,
      handleOpenRedPacket,
      handleDragEnter,
      handleDragOver,
      handleDrop,
      handleScreenShot,
      handleScreenShake,
      handleScroll,
      handleSaveFocusIndex,
      handleAddFace,
      handleOnKeyup,
      handleChooseImage0,
      handleUploadBefore,
      hadleUploadProgress,
      handleSendImage,
      handleUploadSuccess,
      handleUploadError,
      handleChooseImageVisiable,
      handleExceed,
      handleUploadImageDivInput,
      handleClearUploadInfo,
      handleFindUpMsgTime,
      receiveFrindChatLog,
      onSelectFriendChange,
      handleSendMessage,
      handleActiveSidebarRightComponent,
      handleSetAllMsgCount,
      handleLoadMore,
      handleUseRobotStatusChange,
      handleChangeManuStatus,
      handleStartAi,
    };
  },
};
</script>

<style lang="scss" scoped>
.active_right_comp {
  color: #409eff;
  border: 1px solid #409eff;
}
.header_c {
  flex: 1;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  .left {
    display: flex;
    align-items: center;
    .name {
      margin-left: 10px;
      width: 250px;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
      white-space: nowrap;
    }
    .avatar {
      width: 30px;
      height: 30px;
      overflow: hidden;
      border-radius: 100%;
      display: flex;
      align-items: center;
      img {
        width: 30px;
        height: 30px;
      }
    }
  }
  .right {
    flex: 1;
    box-sizing: border-box;
    display: flex;
    justify-content: right;
  }
}
textarea {
  box-sizing: border-box;
  height: 100%;
  line-height: 1.5;
  width: 100%;
  outline: none;
  font-family: "Micrsofot Yahei";
  resize: none;
  background-color: transparent;
}
.me_msg_ontainer {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
}

.lds-ring {
  /* change color here */
  color: #1c4c5b;
  padding: 5px;
}
.lds-ring,
.lds-ring div {
  box-sizing: border-box;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  margin: 2px;
  border: 2px solid currentColor;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: currentColor transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>