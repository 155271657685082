import { getPlatAccountList, setPlatAccountList,//
    getAccountFriendList, setAccountFriendList//
    ,getPlatAccountActiveMap,getAccountFriendActiveMap,//
    setPlatAccountActiveMap,setAccountFriendActiveMap,setSyCfgInfo,getSyCfgInfo,
    setSyCfgInited,getSyCfgInited,setChatingUserId,getChatingUserId,setChatingAccountInfo,getChatingAccountInfo } from '@/utils/wsDataUtils'

import { mergeList, replaceAll, deleteAll, pushAll } from '@/utils/arrayutils'


const state = {
    //平台配置信息
    sysCfgInfo:{},
    sysCfgInfoInited:getSyCfgInited(),
    //当前登录账号
    chatingUserid:getChatingUserId(),
    //当前聊天平台
    chatingPlatId: 1,
    chatingPlatInfo: {},
    //当前聊天账号
    chatingAccountId: getChatingAccountInfo().id,
    chatingAccountInfo: getChatingAccountInfo(),
    //当前聊天好友
    chatingFriendId: 0,
    chatingFriendInfo: {},

    //正在获取消息的好友ID
    fetchingChatLogFriendId:0,

    //聊天对象链，记录哪个平台当前激活的是哪个账号在和谁聊天  [{  chatingPlatId,chatingAccountId}]
    platAccountActiveMap: [],
    //账号和好友聊天对应激活映射  [{ chatingAccountId,chatingFriendId}]
    accountFriendActiveMap:[],

    //登录返回消息
    loginRes: {},

    //账号列表,需要存储平台和账号列表关系 
    platAccountList: [],

    //账号好友列表
    accountFriendList: [],

    //抖音未读消息
    douyinNewMsgCount:0,
    //webh5 未读消息
    webH5NewMsgCount:0,
}

const calNewMsgCount = async(_state,allPlatAccountList,platType)=>{
    let platAccountInfo  = allPlatAccountList.find(item=>item.plat_type == platType)
    let accountList = platAccountInfo?.accountlist
    let msgCount = 0;
    if(accountList && accountList.length > 0){
       let arr = accountList.map(item=>item.new_msg_count || 0)
       for(let c of arr){
          msgCount+= c
       }
    }

    if(platType == '1'){
        _state.douyinNewMsgCount = msgCount
    }else if(platType == '3'){
        _state.webH5NewMsgCount = msgCount
    }else if(platType == '4'){
        _state.weixinMsgCount = msgCount
    }
}


const mutations = {
    //初始化会话内容
    INIT_CHAT_DATA(state){
        getPlatAccountList(state.chatingUserid).then(res=>{
            state.platAccountList = res
        })
        getAccountFriendList(state.chatingUserid,state.chatingAccountId).then(res=>{
            state.accountFriendList = res || []
        })
        getPlatAccountActiveMap(state.chatingUserid).then(res=>{
            state.platAccountActiveMap = res
        })
        getAccountFriendActiveMap(state.chatingUserid).then(res=>{
            state.accountFriendActiveMap = res
        })

        getSyCfgInfo(state.chatingUserid).then(res=>{
            state.sysCfgInfo = res
        })
    },

    SET_SYS_CFG_INFO(state, data){
        state.sysCfgInfoInited = true
        setSyCfgInited(true)
       
        state.sysCfgInfo = data
        setSyCfgInfo(data,state.chatingUserid)
    },
    CLEAR_SYS_CFG_INFO(state){
        state.sysCfgInfoInited = false
        state.sysCfgInfo = {}
        setSyCfgInfo({},state.chatingUserid)
        setSyCfgInited(false)
    },
    SET_CHATING_USERID(state, data){
        state.chatingUserid = data
        setChatingUserId(data)

    },
    SET_FETCHING_CHATLOG_FRIENDID(state, data) {
        state.fetchingChatLogFriendId = data
    },
    SET_LOGIN_RES(state, data) {
        state.loginRes = data
    },
    SET_LOGIN_RES_NULL(state) {
        state.loginRes = {}
    },
    //设置账号列表
    // SET_ACCOUNT_LIST(state, data) {
    //     state.platAccountList = data
    //     setPlatAccountList(data)
    // },
 

    //设置当前聊天平台
    SET_CHATING_PLAT(state, chatingPlatInfo) {
        let chatingPlatId = chatingPlatInfo?.id
        if (!chatingPlatId) {
            return
        }
        state.chatingPlatInfo = chatingPlatInfo
        state.chatingPlatId = chatingPlatId
    },

    //设置当前聊天账号
    SET_CHATING_ACCOUNT(state, chatingAccountInfo) {
        let chatingAccountId = chatingAccountInfo.id
        if (!chatingAccountId) {
            return
        }
        //聊天账号未改变
        if (state.chatingAccountId == chatingAccountId) {
            return
        }
        state.chatingAccountInfo = chatingAccountInfo
        state.chatingAccountId = chatingAccountId
        //更新平台激活的聊天账号
        let findIdx = state.platAccountActiveMap.findIndex(item => item.chatingPlatId == state.chatingPlatId )
        if (findIdx == -1) {
            state.platAccountActiveMap.push(encapChatLinkMap(state.chatingPlatId, chatingAccountId, 0))
        }else{
            state.platAccountActiveMap.splice(findIdx,1,encapChatLinkMap(state.chatingPlatId, chatingAccountId, 0))
        }

        setChatingAccountInfo(chatingAccountInfo)
        setPlatAccountActiveMap(state.platAccountActiveMap,state.chatingUserid)
    },

    //清理正在聊天用户
    CLEAR_CHATING_FRIEND(){
        state.chatingFriendInfo = {}
        state.chatingFriendId = 0
    },
    CLEAR_CHATING_ACCOUNT(){
        state.chatingAccountInfo = {}
        state.chatingAccountId = 0
    },
    //更新或存储,平台账号的聊天好友对象  {platId:z,accountId:x,friendId:y}
    SET_CHATING_FRIEND(state, chatingFriendInfo) {
        let chatingFriendId = chatingFriendInfo?.cid
        if (!chatingFriendId) {
            return
        }
        //聊天好友未改变
        // if (state.chatingFriendId == chatingFriendId) {
        //     return
        // }
        state.chatingFriendInfo = chatingFriendInfo
        state.chatingFriendId = chatingFriendId

        let findIdx = state.accountFriendActiveMap.findIndex(item => item.chatingAccountId == state.chatingAccountId)
        if (findIdx == -1) {
            state.accountFriendActiveMap.push(encapChatLinkMap(state.chatingPlatId, state.chatingAccountId, state.chatingFriendId))
        }else{
            state.accountFriendActiveMap.splice(findIdx,1,encapChatLinkMap(state.chatingPlatId, state.chatingAccountId,state.chatingFriendId))
        }
        
        setAccountFriendActiveMap(state.accountFriendActiveMap,state.chatingUserid)
    },
    //好友聊天人工接管标志:1=是,0=否
    SET_CHATING_FRIEND_AIFLAG(state, is_artificial){
        state.chatingFriendInfo.is_artificial = is_artificial
    },
    //账号列表处理
    UPDATE_ACCOUNT_LIST(state, accountListResp) {
        let type = accountListResp.type
        if (!type) {
            return
        }
         //找到平台的聊天账号列表
         let plat_type = accountListResp.data.plat_type
         if(!plat_type){
             return
         }
        let dataList = accountListResp.data.accountlist
        if (!dataList) {
            dataList = []
        }
        let accountListItemm = state.platAccountList.find(item=>item.plat_type == plat_type)
     
        if(!accountListItemm){
            return
        }
       
        if(!accountListItemm.accountlist){
            accountListItemm.accountlist = []
        }
        let oldAccountList = accountListItemm.accountlist
        //动作类型 1全部，2新增，3更新，4删除，默认1
        if (type == 1) {
            replaceAll(oldAccountList, dataList)
        } else if (type == 2) {
            pushAll(oldAccountList, dataList)
        } else if (type == 3) {
            mergeList(oldAccountList, dataList,'id','id',(oldData,newData)=>{
                //如果新数据没有包含端口信息那么就替换端口信息
                if(newData && !newData?.port_id){
                    newData.port_id = oldData?.port_id
                    newData.end_flg = oldData?.end_flg
                    newData.expire_time = oldData?.expire_time
                    newData.expire_day = oldData?.expire_day
                }
                return newData;
            })
        } else if (type == 4) {
            deleteAll(oldAccountList, dataList)
        } 

        //console.log(state.platAccountList)
        setPlatAccountList(state.platAccountList,state.chatingUserid)
        //计算未读消息
        calNewMsgCount(state,state.platAccountList,plat_type)

    },
    //设置账号好友列表
    SET_ACCOUNT_FRIEND_LIST(state, accountFriendResp) {
        let type = accountFriendResp.type
        if (!type) {
            return
        }

         //账号ID
         let accountId = accountFriendResp.data.aid
         if(!accountId){
             return
         }
   
        let friendlist = accountFriendResp.data.friendlist
        if (!friendlist) {
            friendlist = []
        }

      
        let accountFriendListItemm = state.accountFriendList.find(item=>item.aid == accountId)
        //添加到列表如果不存在
        if(!accountFriendListItemm){
            accountFriendListItemm = {
                aid:accountId,
                friendlist:[],
            }
            state.accountFriendList.push(accountFriendListItemm)
        }

        let oldAccountList = accountFriendListItemm.friendlist
        //动作类型 1全部，2新增，3更新，4删除，默认1
        if (type == 1) {
            replaceAll(oldAccountList, friendlist)
        } else if (type == 2) {
            pushAll(oldAccountList, friendlist)
        } else if (type == 3) {
            // 命令子类型
            let sub_t = accountFriendResp.sub_t
            //3_1 好友聊天人工接管标志变更
            if(sub_t === '3_1'){
                mergeList(oldAccountList, friendlist,'cid','cid',(oldData,newData)=>{
                    //修改AI接管标识
                    oldData.is_artificial = newData.is_artificial
                    return oldData
                })
            }else{
                mergeList(oldAccountList, friendlist,'cid','cid',(oldData,newData)=>{
                    //保留AI接管标识
                    let is_artificial = oldData.is_artificial
                    newData.is_artificial = is_artificial
                    return newData
                })
            }

        } else if (type == 4) {
            deleteAll(oldAccountList, friendlist,'cid','cid')
        } 
        setAccountFriendList(state.accountFriendList,state.chatingUserid)
    },

}

const actions = {

}
const getters = {
    getSysConfig(state){
        return state.sysCfgInfo
    }
}

//聊天链对象
function encapChatLinkMap(chatingPlatId, chatingAccountId, chatingFriendId) {
    return {
        chatingPlatId,
        chatingAccountId,
        chatingFriendId
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
