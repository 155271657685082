/*
 * 枚举插件
 * 此插件为 feihu technology 自创的插件
 *
 * @Author:    feihu technology-主任：Hihong
 * @Date:      2022-09-06 20:51:03
 * @Wechat:    hihong2024
 * @Email:     hihong@163.com
 * @Copyright  feihu technology （ https://feihu.net ），Since 2016
 */
import _ from 'lodash';
import { FLAG_NUMBER_ENUM } from '@/constants/common-const';

export default {
  install: (app, smartEnumWrapper) => {
    const smartEnumPlugin = {};
    /**
     * 根据枚举值获取描述
     * @param {*} constantName 枚举名
     * @param {*} value          枚举值
     * @returns
     */
    smartEnumPlugin.getDescByValue = function (constantName, value) {
      if (!smartEnumWrapper || !Object.prototype.hasOwnProperty.call(smartEnumWrapper, constantName)) {
        return '';
      }
      // boolean类型需要做特殊处理
      if (constantName === 'FLAG_NUMBER_ENUM' && !_.isUndefined(value) && typeof value === 'boolean') {
        value = value ? FLAG_NUMBER_ENUM.TRUE.value : FLAG_NUMBER_ENUM.FALSE.value;
      }

      let smartEnum = smartEnumWrapper[constantName];
      for (let item in smartEnum) {
        if (smartEnum[item].value === value) {
          return smartEnum[item].desc;
        }
      }
      return '';
    };
    /**
     * 根据枚举名获取对应的描述键值对[{value:desc}]
     * @param {*} constantName 枚举名
     * @returns
     */
    smartEnumPlugin.getValueDescList = function (constantName) {
      if (!Object.prototype.hasOwnProperty.call(smartEnumWrapper, constantName)) {
        return [];
      }
      const result = [];
      let targetSmartEnum = smartEnumWrapper[constantName];
      for (let item in targetSmartEnum) {
        result.push(targetSmartEnum[item]);
      }
      return result;
    };

    /**
     * 根据枚举名获取对应的value描述键值对{value:desc}
     * @param {*} constantName 枚举名
     * @returns
     */
    smartEnumPlugin.getValueDesc = function (constantName) {
      if (!Object.prototype.hasOwnProperty.call(smartEnumWrapper, constantName)) {
        return {};
      }
      let smartEnum = smartEnumWrapper[constantName];
      let result = {};
      for (let item in smartEnum) {
        let key = smartEnum[item].value + '';
        result[key] = smartEnum[item].desc;
      }
      return result;
    };

    /**
     * 根据枚举名获取对应的枚举数组 [{value:xx,desc:xx},]
     * @param {*} constantName 枚举名
     * @returns
     */
    smartEnumPlugin.getEnumList = function (constantName) {
      if (!Object.prototype.hasOwnProperty.call(smartEnumWrapper, constantName)) {
        return {};
      }
      let smartEnum = smartEnumWrapper[constantName];
      let result = [];
      for (let item in smartEnum) {
        result.push(smartEnum[item])
      }
      return result;
    };

    /**
     * 根据枚举，和value 获取item
     * @param {*} constantName 枚举名
     * @returns
     */
    smartEnumPlugin.getEnumItemByValue = function (constantName,value) {
      if (!Object.prototype.hasOwnProperty.call(smartEnumWrapper, constantName)) {
        return {};
      }
      let smartEnum = smartEnumWrapper[constantName];
      for (let item in smartEnum) {
        if(smartEnum[item].value === value){
            return smartEnum[item];
        }
      }
      return {};
    };

    app.config.globalProperties.$smartEnumPlugin = smartEnumPlugin;
    app.provide('smartEnumPlugin', smartEnumPlugin);
  },
};
