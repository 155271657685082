<!-- //设置 -->
<template>
  <el-dialog v-model="dialogVisible" title="Tips" width="50%" draggable :close-on-click-modal="false">
    <template #title> <i class="iconfont icon-peizhi2"></i> 设置页面{{ activeName }} </template>
    <div>
      <div class="ntMain__cont flex1 flexbox flex-col">
        <div class="pad20">
          <el-tabs
            v-model="activeName"
            tabPosition="left"
            @tab-click="handleTabClicked"
          >
            <el-tab-pane label="账号设置" name="1">
              <AccountSet />
            </el-tab-pane>
            <el-tab-pane label="消息相关设置" name="2">
              <Messageset />
            </el-tab-pane>
            <el-tab-pane label="提示音设置" name="3">
              <NoticeMusic />
            </el-tab-pane>
            <el-tab-pane label="快捷按键" name="4">
              <ShorCutKey />
            </el-tab-pane>
            <el-tab-pane label="系统配置" name="5">
              <div>
                <el-button @click="handleRefreshSysCfgInfo">
                  刷新系统配置
                </el-button>

                <el-tooltip
                  content="刷新系统配置信息，如：欢迎语，线索分类等信息"
                  placement="bottom"
                  effect="light"
                >
                  <i class="iconfont icon-tishi1"></i>
                </el-tooltip>
              </div>
            </el-tab-pane>

            <el-tab-pane label="关于" name="6">
              <div class="flexbox flex-alignc flex-col">
                <div class="flexbox mt-10">
                  <label
                    style="
                      padding-right: 30px;
                      text-align: right;
                      min-width: 100px;
                    "
                    >当前版本：V1.2.32</label
                  >
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
    <template #footer> </template>
  </el-dialog>
</template>
<script>
import { reactive, toRefs, inject, computed, ref, provide } from "vue";
import { useStore } from "vuex";
import { initUserSystemData } from "@/api/userapi";
import { ElMessage } from "element-plus";
import ShorCutKey from "./components/shortcut_key.vue";
import NoticeMusic from "./components/notice_music.vue";
import Messageset from "./components/message_set.vue";
import AccountSet from "./components/account_set.vue";

export default {
  props: { onLogout: { type: Function, default: null } },
  components: {
    ShorCutKey,
    NoticeMusic,
    Messageset,
    AccountSet,
  },
  setup(props) {
    const v3layer = inject("v3layer");
    const store = useStore();
    const userInfo = computed(() => {
      return store.state.user.user;
    });

    const dialogVisible = ref(false);
    const activeName = ref('1')


    const data = reactive({

      checkArray: [
        "开启新消息提醒声音",
        "保留聊天记录",
        "回车键发送消息",
        "自动升级系统",
        "允许陌生人查看朋友圈",
        "加我为朋友时需要验证",
      ],
      checkedLS: [
        "开启新消息提醒声音",
        "保留聊天记录",
        "自动升级系统",
        "允许陌生人查看朋友圈",
      ],
    });
    provide("activeTab",activeName)
    provide("dialogVisible",dialogVisible)
    const handleTabClicked = (tabPaneName, event) => {
    };
    const handleLogout = () => {
      let $el = v3layer({
        type: "ios",
        content: "确定要退出当前账号吗？",
        xclose: false,
        btns: [
          {
            text: "取消",
            click: () => {
              $el.close();
            },
          },
          {
            text: "退出",
            style: "color:#f57b15",
            click: () => {
              $el.close();
              typeof props.onLogout === "function" && props.onLogout();
            },
          },
        ],
      });
    };
    const checkUpdate = () => {
      v3layer({
        type: "toast",
        icon: "loading",
        content: "正在检查中...",
        time: 2,
        onEnd: () => {
          v3layer.message({
            icon: "info",
            content: "已是最新版本！",
            xclose: true,
          });
        },
      });
    };

    //刷新系统配置信息
    const handleRefreshSysCfgInfo = () => {
      initUserSystemData().then(() => {
        ElMessage({
          message: "刷新成功",
          type: "success",
          plain: true,
        });
      });
    };
    //显示对话框
    const show = () => {
      dialogVisible.value = true;
    };

    return {
      activeName,
      ...toRefs(data),
      userInfo,
      dialogVisible,
      handleTabClicked,
      handleLogout,
      checkUpdate,
      handleRefreshSysCfgInfo,
      show,
    };
  },
};
</script>
