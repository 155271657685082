<!-- //中间栏模块 -->
<template>
  <div class="sidebar_left">
    <div v-for="item in menuList" :key="item.value" style="width:100%" >
      <div v-if="item.checked" class="item" :class="{ active_plat: chatingPlatId == item.value }" @click="handleGetAccountList(item.value)">
        <div style="position: relative">
          <i class="iconfont" :class="item.icon"></i>
          {{ item.desc }}
          <em class="m_vui__badge" v-if="item.value == 1 && douyinNewMsgCount > 0">{{ douyinNewMsgCount }}</em>
          <em class="m_vui__badge" v-else-if="item.value == 3 && webH5MsgCount > 0">{{ webH5MsgCount }}</em>
          <em class="m_vui__badge" v-else-if="item.value == 4 && weixinMsgCount > 0">{{ weixinMsgCount }}</em>
        </div>
      </div>
    </div>

    <!-- <div class="item" :class="{active_plat:chatingPlatId == 1}" @click="handleGetAccountList(1)" >
        <div >
          <i class="iconfont icon-douyin"></i>
          抖音
        </div>
    </div> -->

    <!-- <div class="item" :class="{active_plat:chatingPlatId == 2}" @click="handleGetAccountList(2)">
      <div class="item_inner item_t item_b">
        小红书
      </div>
    </div> -->
    <!-- <div class="item" :class="{active_plat:chatingPlatId == 3}" @click="handleGetAccountList(3)">
      <div class="item_inner item_b">
        <i class="iconfont icon-kuaishou"></i>
          快手
      </div>
    </div> -->
    <!-- <div class="item" :class="{active_plat:chatingPlatId == 4}" @click="handleGetAccountList(3)">
      <div class="item_inner item_b">
        <i class="iconfont icon-zhinengwangyehuise"></i>
          网页
      </div>
    </div> -->
    <!-- <div class="item_bottom">
    </div> -->
  </div>
</template>
<script>
// 引入动态组件（中间栏）
import { toRefs, onMounted, computed, reactive, ref, inject } from "vue";
import { getAccountList } from "@/api/chatapi";
import { useStore } from "vuex";
import { ElNotification } from "element-plus";
export default {
  components: {},
  setup() {
    let store = useStore();

    const smartEnumPlugin = inject("smartEnumPlugin");

    const platIdRef = ref(undefined);

    const menuList = computed(()=>{
      return store.state.user.user_plat_msg_menu
    }) 
    //当前选中平台
    const chatingPlatId = computed(() => {
      return store.state.wsData.chatingPlatId;
    });

    //douyin未读消息数
    const douyinNewMsgCount = computed(() => {
      let c = store.state.wsData.douyinNewMsgCount;
      return c > 999 ? 999 : c;
    });
    //webh5未读消息数
    const webH5MsgCount = computed(() => {
      let c = store.state.wsData.webH5NewMsgCount;
      return c > 999 ? 999 : c;
    });
    //微信可覅未读消息数
    const weixinMsgCount = computed(() => {
      let c = store.state.wsData.weixinMsgCount;
      return c > 999 ? 999 : c;
    });

    const data = reactive({});
    function handleGetAccountList(platId) {
      //暂时只开通抖音
      if (platId !== 1 && platId !== 3 && platId !== 4) {
        ElNotification({
          title: "提示信息",
          message: "当前应用未开通",
          type: "info",
        });
        return;
      }
      // if(platIdRef.value === platId){
      //   return
      // }

      platIdRef.value = platId;
      //记录正在聊天的平台
      store.commit("wsData/SET_CHATING_PLAT", { id: platId });
      //清理正选择的账号信息
      store.commit("wsData/CLEAR_CHATING_ACCOUNT");
      // //清理正选择的好友信息
      store.commit("wsData/CLEAR_CHATING_FRIEND");

      getAccountList({ plat_type: platId });
    }
    onMounted(() => {
      //获取显示的平台第一项
      let idx = menuList.value?.findIndex(item=>item.checked)
      //默认抖音
      if(idx>=0){
        handleGetAccountList(menuList.value[idx].value);
      }
    });
    return {
      douyinNewMsgCount,
      webH5MsgCount,
      weixinMsgCount,
      smartEnumPlugin,
      menuList,
      ...toRefs(data),
      handleGetAccountList,
      chatingPlatId,
    };
  },
};
</script>

<style lang="scss" scoped>
.m_vui__badge {
  display: flex;
  justify-content: center;
  align-items: center;
  writing-mode: horizontal-tb;
  position: absolute;
  letter-spacing: normal;
  max-width: 20px;
  overflow: hidden;
  top: -6px;
  right: -6px;
  z-index: 998;
}

.active_plat {
  //color: #0aea64 !important;
  color: white !important;
  background-image: linear-gradient(180deg, #2e2f2f, #3a3535) !important;
}

.sidebar_left {
  // position:absolute;
  // left: -35px;
  // top:0;
  // display: inline-block;
  background-color: rgba(24, 24, 24, 0.8);
  position: relative;
  width: 35px;
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 5px 0 0 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  // border-radius: 3px 0 0 3px;
  height: 100%;

  .item {
    padding-top: 5px;
    align-self: center;
    color: white;
    position: relative;
    box-sizing: border-box;
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
    height: 80px;
    width: 100%;
    writing-mode: vertical-rl;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: rgba(255, 255, 255, 0.6);
    letter-spacing: 3px;

    .icons {
      position: absolute;
      top: 5px;
    }

    .item_t {
      border-top: 1px solid rgba(255, 255, 255, 0.2);
    }

    .item_b {
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }

    //
    // background-image: url('@assets/img/dy.png');
  }

  // .item_bottom{
  //   position:absolute;
  //   bottom: 0;
  //   width: 100%;
  //   height: 5px;
  //   background-color: #0aea64;

  // }

  .item_p {
    // padding-top:10px;
  }

  // .item1{
  //   background-image: url('~@/assets/img/dy.png');
  //   background-repeat: no-repeat;
  // }
  .item:hover {
    color: rgba(255, 255, 255, 0.9);
    background-color: rgba(46, 47, 47, 0.9);
  }

  .ky {
    // background-image: url('@/assets/img/dy.png');
  }
}
</style>