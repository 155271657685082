export const PLAT_TYPE_MENU_TOOL = {
  DOUYIN: {
    value: 1,
    desc: "抖音",
    icon: "icon-douyin",
  },
  // KUAISHOU: {
  //   value: 2,
  //   desc: "小红书",
  //   icon: "icon-xiaohongshu",
  // },
  WEIXIN: {
    value: 4,
    desc: "微信",
    icon: "icon-weixin1",
  },
  WANGYE: {
    value: 3,
    desc: "网页",
    icon: "icon-zhinengwangyehuise",
  },

};

export default {
  PLAT_TYPE_MENU_TOOL,
};
