<!-- //中间栏模块 -->
<template>
  <div class="leftContainer" :class="{collapsedLeft:store.state.user.isLeftCollapsed}">
    <div class="title" >
      <div class="title_util">
        <!-- <el-input
          v-model="input2"
          placeholder="Type something"
          :prefix-icon="Search"
        /> -->
        <!-- <el-icon :size="20">
        <Search />
      </el-icon> -->

        <el-input v-model="searchKeyWords"  size="small" style="padding:0;" placeholder="搜索账号"  >
          <template #prefix>
            <i class="iconfont icon-search"></i>
          </template>
        </el-input>
        <!-- <span @click="haldleSetLeftCollapsedToggled" :class="{collapsedLeftIcon:store.state.user.isLeftCollapsed,uncollapsedLeft:!store.state.user.isLeftCollapsed}">
          <i class="iconfont icon-shousuo"></i>
        </span> -->
      </div>
    </div>
    <!-- <div class="title_tip">
      账号数{{ accountList.length }},已加载账号数:{{ 100 }}
    </div> -->
 
    <v3-scroll autohide zIndex="2021">
      <div class="item_contaimer">
        <div>
          <el-alert  type="info" v-if="!accountList || accountList?.length ==0">
            <div style="font-size: 12px;">账号未显示
              <el-tooltip
                    class="box-item"
                    effect="light"
                    placement="top-start"
                  >
                  <template #content>
                      <p>常见问题：</p>
                      <ul>
                        <li class="m_desc">1. 您新分配的账号需要<em class="m_color_p">收到第一条新信息</em>后才会显示哦!</li>
                      </ul>
                  </template>
              <i class="iconfont icon-tishi1"></i>
              </el-tooltip>
            </div>
          </el-alert>
        </div>
          <div class="item" v-for="(item, index) in accountList" :key="index" @click="handleSelectAccouont(item)" :class="{selectAccount:item.id === selectedAccountId}">
            <douyinaccount v-if="chatingPlatId == 1" @showAccountInfo="handleShowUinfoLayer" @changeAiStatus="handleUseRobotStatusChange" :item="item"></douyinaccount>
            <webh5account v-else-if="chatingPlatId == 3" @showAccountInfo="handleShowUinfoLayer" @changeAiStatus="handleUseRobotStatusChange" :item="item"></webh5account>
            <wechataccount v-else-if="chatingPlatId == 4" @showAccountInfo="handleShowUinfoLayer" @changeAiStatus="handleUseRobotStatusChange" :item="item"></wechataccount>
            <!-- <div class="selectFlag" v-if="item.id === selectedAccountId">

            </div> -->
          </div>
      </div>
    </v3-scroll>
    <!-- 卡片模板 -->
    <v3-layer
      v-model="isShowUinfoLayer"
      :follow="uInfoLayerPos"
      opacity="0"
      teleport="body"
      :zIndex="999"
    >
      <div class="rlay__uCards">
        <div class="item flexbox flex-alignc vui__hairline-bottom">
          <span class="lbl flex1"
            ><em class="fs-18">{{mainData.selectAccountInfoRow.username || '--'}}</em
            ><em class="iconfont icon-male ml-10 c-46b6ef"></em
            ></span
          >
          <img class="uimg"  :src="mainData.selectAccountInfoRow.avatar || '@assets/img/uimg/img-avatar01.jpg'" />
        </div>
        <div class="item flexbox flex-alignc vui__hairline-bottom">
          <label class="lbl flex1">分类：{{ $syscfgNameByValue('customer_type_list',mainData.selectAccountInfoRow.customer_type_id) }}</label>
        </div>
      </div>
    </v3-layer>
  </div>
</template>
<script> 
// 引入动态组件（中间栏）
import {  reactive, toRefs, computed,inject,onMounted, onUnmounted,ref } from "vue";
import { getAccountFriendList } from "@/api/chatapi";
import {changeAiStatus} from '@/api/aichatapi'
import { useStore } from "vuex";
import douyinaccount from "./douyinaccount.vue";
import webh5account from "./webh5account.vue";
import wechataccount from "./wechataccount.vue";

export default {
  components: {douyinaccount,webh5account,wechataccount},
  setup() {
    const bus = inject("$eventBus");
    const wsCmd = inject("wsCmd");
    let store = useStore();

     
    const data = reactive({
      searchKeyWords:'',
      status: "online",
      isShowUinfoLayer: false,
      uInfoLayerPos: null,
    });
    const mainData = reactive({
  
      selectAccountInfoRow:{}
    })


    //接收账号信息变更
    function receiveAccountList(accountListResp) {
        if (accountListResp && accountListResp.code === 1) {
          store.commit('wsData/UPDATE_ACCOUNT_LIST',accountListResp)
        }
    }



    //当前选中平台
    const chatingPlatId = computed(() => {
      return store.state.wsData.chatingPlatId
    });

     //当前选中账号
     const selectedAccountId = computed(() => {
      let chatLink = store.state.wsData.platAccountActiveMap.find(item=>item.chatingPlatId == store.state.wsData.chatingPlatId)
      let chatingAccountId =  store.state.wsData.chatingAccountId || chatLink?.chatingAccountId
      return chatingAccountId
    });

    const platAccountActiveMap = computed(()=>{
      return    store.state.wsData.platAccountActiveMap
    })


    //账号列表
    const accountList = computed(() => {
      let _accountListDataStore =  store.state.wsData.platAccountList.find(item=>item.plat_type == chatingPlatId.value);

      let accountList = _accountListDataStore?.accountlist
      if(accountList && accountList.length > 0){
          //如果没设置选中账号则处理
          let chatingAccountId = store.state.wsData.chatingAccountInfo?.id
          if(!chatingAccountId){
            let chatingAccount = accountList.find(item => item.id == selectedAccountId.value)
            if(chatingAccount?.id){
              store.commit("wsData/SET_CHATING_ACCOUNT", chatingAccount);
              handleSelectAccouont(chatingAccount)
            }else{
              //默认选中第一条
              store.commit("wsData/SET_CHATING_ACCOUNT", accountList[0]);
              handleSelectAccouont(accountList[0])
            }
          }
      }

      if(!accountList){
        return []
      }
      if(data.searchKeyWords){
        return accountList.filter(item=>haldleFilterAccount(item,data.searchKeyWords)) || [];
      }
      return accountList;
    });

     //过滤账号
     const haldleFilterAccount = (friendItem,searchKeyWords)=>{


      if(searchKeyWords){
        let _searchKeywords = searchKeyWords.replace(/^\s\s*/, '').replace(/\s\s*$/, '')
        if(_searchKeywords.length == 0){
          return true
        }
        let title = friendItem.username
        if(!title){
          return false
        }
        return title.includes(_searchKeywords)
      }else{
        return true
      }
    }



    //左侧边栏收缩
    const haldleSetLeftCollapsedToggled = ()=>{
      store.commit("user/SET_LEFT_COLLAPSED_TOGGLED");
    }


    // 用户卡片
    const handleShowUinfoLayer = (row) => {
    
      //如果折叠起来了直接事件冒泡就好了
      if(store.state.user.isLeftCollapsed){
        return
      }

      // let pos = [e.clientX, e.clientY];
      data.isShowUinfoLayer = true;
      data.uInfoLayerPos = row.pos;
      mainData.selectAccountInfoRow = row.rowItem
    };

    //账号选择
    function handleSelectAccouont(item){
      store.commit("wsData/SET_CHATING_ACCOUNT", item);
      //清理正选择的好友信息
      store.commit("wsData/CLEAR_CHATING_FRIEND");
      //获取账号好友列表
      getAccountFriendList({aid:item.id})
      return
    }

    //当前聊天账号
    const chatingAccount = computed(() => {
      return store.state.wsData.chatingAccountInfo;
    });

    //切换机器人接管状态
    const handleUseRobotStatusChange = (item)=>{
      let data = {aid:item.id,status:item.use_robot_status}
      changeAiStatus(data,item.id)
    }

    const receiveAiStatusResult = (resp)=>{
      console.log('----------------')
      console.log(resp)
    }

    onMounted(() => {
      //当前选中账号
      //handleSelectAccouont(chatingAccount.value)

      bus.on(`${wsCmd.cmd_2_resp}`, receiveAccountList); // listen
      bus.on(`${wsCmd.cmd_21_resp}`, receiveAiStatusResult); // listen
    });

    onUnmounted(()=>{
      bus.off(`${wsCmd.cmd_2_resp}`); // listen
      bus.off(`${wsCmd.cmd_21_resp}`); // listen
    })

    return {
      ...toRefs(data),
      handleShowUinfoLayer,
      accountList,
      selectedAccountId,
      mainData,
      platAccountActiveMap,
      store,
      chatingAccount,
      chatingPlatId,
      haldleFilterAccount,
      handleSelectAccouont,
      haldleSetLeftCollapsedToggled,
      receiveAccountList,
      handleUseRobotStatusChange,
    };
  },
};
</script>

<style lang="scss" scoped>
  @import url(./accountitem.scss);
</style>
   
   